import React from "react";

import { Container, Button } from "react-bootstrap";
import LOGO_GREEN from "../assets/images/logo-green.svg";

const LetsCraftSection = () => {
	return (
		<div className="section service service-five">
			<Container fluid="xl">
				<div className="wrapper">
					<h2>Let's craft your next big thing together!</h2>
					<Button
						href="/contact"
						size="lg"
						variant="light"
						type="button"
						className="yes-button"
					>
						<img src={LOGO_GREEN} alt="logo-green" /> Contact Now
					</Button>
				</div>
			</Container>
		</div>
	);
};

export default LetsCraftSection;
