import React from 'react';

const ProductMainHeader = (props) => {
    return (
        props.heading === "Doconnect" ?
            <div className={`productmainhead ${props.headerColor}`}><span>Doc</span>onnect</div>
        :
            <div className={`productmainhead ${props.headerColor}`}>{props.heading}</div>
    )
}

export default ProductMainHeader
