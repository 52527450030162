import React from 'react';

import LOGO from '../assets/images/LogoNavigation.svg';
import MENUICON from '../assets/images/menu-icon.svg';
import MENUICONWHITE from '../assets/images/menu-icon-white.svg';
import LOGOBLACK from '../assets/images/logo/logo-black@2x.png';
import LOGOWHITE from '../assets/images/logo/logo-white@2x.png';

import { Container, Navbar, Nav } from 'react-bootstrap';


const Header = (props) => {

    const { homepage, handleClick, navClass, currentSlide } = props;

    const darkNavbarIndex = [1, 2, 3, 4, 5, 6, 7]
    
    let slideIndex = 0;
    if(currentSlide !== undefined){
        slideIndex = currentSlide.direction === 'down' ? currentSlide.index + 1 : currentSlide.index - 1
    }

    return (
        <Navbar expand="xl" fixed="top" className={`${homepage ? 'mainNavbar' : 'mainNavigation'} ${navClass}`} style={{paddingTop: window.pageYOffset === 0 ? '' : '7px'}}>
            <Container fluid="xl" className="py-1">
                <Navbar.Brand href="/">
                    <img src={LOGO} alt="logo-main" className="d-block d-sm-none" height="32" style={{float: 'left'}} />
                    <img src={`${!homepage || darkNavbarIndex.find((index) => index === slideIndex) ? LOGOBLACK : LOGOWHITE}`} height="23" alt="logo" />
                </Navbar.Brand>
                <div className="d-block d-sm-none m-auto my-lg-0 d-none d-sm-block">
                    <a href="/">
                        <img src={LOGO} alt="logo-main" />
                    </a>
                </div>
                <Nav className="d-flex menu-nav d-block d-sm-none" style={{ maxHeight: '100px' }} navbarScroll>
                    <Nav.Link href="#" onClick={handleClick}>
                        <img src={!homepage || darkNavbarIndex.find((index) => index === slideIndex) ? MENUICON : MENUICONWHITE} alt="menu-icon" style={{marginTop: '-3px'}} />
                    </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls="navbarScroll" className="d-none" />
                <Navbar.Collapse id="navbarScroll" className="d-none d-sm-block">
                    <div className="m-auto my-2 my-lg-0">
                        <a href="/">
                            <img src={LOGO} alt="logo-main" />
                        </a>
                    </div>
                    <Nav className="d-flex menu-nav" style={{ maxHeight: '100px' }} navbarScroll>
                        <Nav.Link href="/services" className={`${!homepage || darkNavbarIndex.find((index) => index === slideIndex) ? 'nav-item-text-black' : 'nav-item-text'}`}>Services</Nav.Link>
                        <Nav.Link href="/our-work" className={`${!homepage || darkNavbarIndex.find((index) => index === slideIndex) ? 'nav-item-text-black' : 'nav-item-text'}`}>Work</Nav.Link>
                        <Nav.Link href="#" onClick={handleClick}>
                            <img src={!homepage || darkNavbarIndex.find((index) => index === slideIndex) ? MENUICON : MENUICONWHITE} alt="menu-icon" style={{marginTop: '-3px'}} />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header
