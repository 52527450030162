import React, { useContext, useEffect } from "react";

import { Helmet } from "react-helmet";
import { Row, Col, Container } from "react-bootstrap";

import BlogContext from "../context/blogContext";
import { Layout, Footer, LetsCraftSection, BlogSection } from "../components";
import ReactGA from "react-ga";

const Blogs = () => {
	const { blogs } = useContext(BlogContext);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	ReactGA.pageview("bloglist");

	return (
		<React.Fragment>
			<Helmet>
				<title>Blog | Nxinfoway</title>
			</Helmet>
			<Layout>
				<div className="section service head">
					<Container fluid="xl">
						<div className="wrapper">
							<h1>We Help Businesses</h1>
							<p className="title">
								Empower people through technology and innovative solutions.
								Let's craft your next big thing together !
							</p>
						</div>
					</Container>

					<div className="pb-5">
						<Container fluid="xl">
							<Row>
								{blogs &&
									blogs.reverse().map((item, index) => {
										return (
											<Col
												lg={4}
												md={4}
												sm={6}
												xs={12}
												key={index}
												className="mb-4 blog-section"
											>
												<BlogSection item={item} />
											</Col>
										);
									})}
							</Row>
						</Container>
					</div>
				</div>
				<LetsCraftSection />
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default Blogs;
