import React from "react";

import { Row, Col, Container, Image } from "react-bootstrap";
import Fade from "react-reveal/Fade";

import { DEFAULT } from "../utils/keyWords";

import { Layout, LetsCraftSection, Footer, WorkProfile } from "../components";
import {
	WORK_PROFILE,
	BRANDING,
	SHOWCASES,
	ldJson,
	DOMAIN,
} from "../utils/constants";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const OurWork = () => {
	ReactGA.pageview("OurWorkPage");

	const WorkCommon = ({ pside, children }) => {
		return (
			<WorkProfile pside={pside}>
				<Fade bottom duration={1500}>
					{children}
				</Fade>
			</WorkProfile>
		);
	};

	const WorkProfileSection = ({ item, pside }) => {
		return (
			<WorkCommon pside={pside}>
				<a href={item.link}>
					<Image src={item.img} alt={item.heading} className="mb-3" fluid />
					<div className="workprofile-content">
						<h5 className="m-0">{item.heading}</h5>
						<p className="m-0">
							{item.content} - {item.type}
						</p>
					</div>
				</a>
			</WorkCommon>
		);
	};

	const WorkShowCase = ({ item, pside }) => {
		return (
			<WorkCommon pside={pside}>
				<Image src={item} alt={item} fluid />
			</WorkCommon>
		);
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>
				Custom Mobile and Website Development Company and Services India and USA | Nxinfoway
				</title>
				<meta
					name="description"
					content="Nxinfoway portfolio consists many projects developed in Android, IOS, Larawel, WordPress, UI and UX, Node.JS, Magento, PHP ASP.NET, AngularJS, ReactJS etc."
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="web development portfolio, mobile app development portfolio, web design and development, graphics designing work"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="Custom Mobile and Website Development Company and Services India and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Nxinfoway portfolio consists many projects developed in Android, IOS, Larawel, WordPress, UI and UX, Node.JS, Magento, PHP ASP.NET, AngularJS, ReactJS etc."
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content="Custom Mobile and Website Development Company and Services India and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Nxinfoway portfolio consists many projects developed in Android, IOS, Larawel, WordPress, UI and UX, Node.JS, Magento, PHP ASP.NET, AngularJS, ReactJS etc."
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Digital Marketing Agency,Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />

				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
			</Helmet>
			<Layout>
				<div className="section our-work head">
					<Container fluid="xl">
						<div className="wrapper">
							<h1>Our Work</h1>
						</div>
						<Row>
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row>
									<Col lg={10} md={10} sm={10} xs={12}>
										<p className="title work-para">
											Explore our projects, in-house products, mobile apps, web
											apps, branding, design concepts.
										</p>
									</Col>
								</Row>
								<Row>
									{WORK_PROFILE &&
										WORK_PROFILE.map((item, index) => {
											return (
												index % 2 === 0 && (
													<WorkProfileSection
														item={item}
														pside="right"
														key={index}
													/>
												)
											);
										})}
								</Row>
							</Col>
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row>
									{WORK_PROFILE &&
										WORK_PROFILE.map((item, index) => {
											return (
												index % 2 !== 0 && (
													<WorkProfileSection
														item={item}
														pside="left"
														key={index}
													/>
												)
											);
										})}
								</Row>
							</Col>
						</Row>
					</Container>
				</div>

				<div className="section our-work brand">
					<Container fluid="xl">
						<div className="wrapper">
							<h2>Branding & Identity</h2>
							<p className="title m-0">
								Logos, Brandmark, Symbols, Visual identity
							</p>
						</div>
						<Row>
							{BRANDING &&
								BRANDING.map((item, index) => {
									return (
										<Col lg={4} md={4} sm={4} xs={12} key={index}>
											<div className="workprofile-brand">
												<Fade bottom duration={1500}>
													<Image
														src={item.img}
														alt={item.heading}
														fluid
														className="mb-3"
													/>
													<div className="workprofile-content">
														<h5 className="m-0">{item.heading}</h5>
														<p className="m-0">
															{item.content} - {item.type}
														</p>
													</div>
												</Fade>
											</div>
										</Col>
									);
								})}
							<Col lg={4} md={4} sm={4} xs={12} className="d-none d-sm-block">
								<div className="workprofile-brand brand-section">
									<div className="workprofile-contact brand-section-inner">
										<p>
											To learn about our work process, or more branding
											examples..
										</p>
										<h2 className="m-0">Get in Touch.</h2>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>

				<div className="section our-work showcase">
					<Container fluid="xl">
						<div className="wrapper">
							<h2>Showcase & Exps</h2>
						</div>
						<Row>
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row>
									<Col lg={10} md={10} sm={10} xs={12}>
										<p className="title work-para">
											Mockups, concepts, experiments
										</p>
									</Col>
								</Row>
								<Row>
									{SHOWCASES &&
										SHOWCASES.map((item, index) => {
											return (
												index % 2 === 0 && (
													<WorkShowCase item={item} pside="right" key={index} />
												)
											);
										})}
								</Row>
							</Col>
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row>
									{SHOWCASES &&
										SHOWCASES.map((item, index) => {
											return (
												index % 2 !== 0 && (
													<WorkShowCase item={item} pside="left" key={index} />
												)
											);
										})}
								</Row>
							</Col>
						</Row>
					</Container>
				</div>

				<div className="section service about">
					<Container fluid="xl">
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="simple-tags text-center mt-4 pb-5">
									{DEFAULT &&
										DEFAULT.map((item, index) => {
											return <span key={index}>{item}</span>;
										})}
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<LetsCraftSection />
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default OurWork;
