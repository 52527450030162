import React, { useRef, useState } from "react";
import {
	Row,
	Col,
	Card,
	Container,
	Form,
	FloatingLabel,
	Button,
} from "react-bootstrap";
import { toast } from "react-toastify";

import {
	CONTACTFORM_URL,
	INSTA_URL,
	SKYPE_URL,
	LINKEDIN_URL,
	FACEBOOK_URL,
	TWITTER_URL,
	CURRENT_YEAR,
} from "../utils/constants";
import { SmLink, Testimonial, FooterLinks } from "../components";

import LOGO_BIG from "../assets/images/logo-big.svg";
import TWITTER_ICON from "../assets/images/social-icons/twitter-logo.svg";
import FACEBOOK_ICON from "../assets/images/social-icons/facebook-icon.svg";
import LINKEDIN_ICON from "../assets/images/social-icons/linkedIn-icon.svg";
import INSTAGRAM_ICON from "../assets/images/social-icons/instagram-icon.svg";
import SKYPE_ICON from "../assets/images/social-icons/skype-icon.svg";
import LOGO_NAVIGATION from "../assets/images/LogoNavigation.svg";

import "react-toastify/dist/ReactToastify.css";

const Footer = (props) => {
	const [fullname, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [lookingfor, setLookingFor] = useState("");
	const [error, setError] = useState({});
	const [isDisabled, setIsDisabled] = useState(false);

	const { refId } = props;
	const contactInfo = useRef();

	const scrollTOSection = (refId) => {
		refId.current.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		const error = {};
		const defaultError = "This fields is required.";

		if (!fullname) {
			error["fullname"] = defaultError;
			setError(error);
		}

		if (!email) {
			error["email"] = defaultError;
			setError(error);
		}

		if (!lookingfor) {
			error["lookingfor"] = defaultError;
			setError(error);
		}

		let isValidate = Object.keys(error).length;
		if (!isValidate) {
			let payload = { fullname, email, lookingfor };
			setIsDisabled(true);

			fetch(CONTACTFORM_URL, {
				method: "POST",
				body: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
				},
			}).then(
				(response) => {
					if (response.status === 200) {
						toast.success("Thank you! We will contact you soon.");
					}
					setIsDisabled(false);
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};

	return (
		<React.Fragment>
			<div className="section greenbg client-testimonial" ref={refId}>
				<Container fluid="xl">
					<div className="sectionHead pb-lg-4">What clients says</div>
					<div className="mt-5 mb-5 mx-2">
						<Testimonial />
					</div>
					<Row className="pt-5 pb-5 mt-5">
						<Col lg={6} md={6} sm={6} xs={12} className="text-start">
							<SmLink
								url="#"
								onClick={() => scrollTOSection(contactInfo)}
								textAlign="text-start"
								label="Let’s Discuss Your Project"
							/>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="section darkbg inquiry-form" ref={contactInfo}>
				<Container fluid="xl">
					<Row>
						<Col lg={5} md={5} sm={5} xs={12}>
							<div className="sectionHead">Let's Connect</div>
							<div className="sectionSMHead mt-3 mt-xl-4">
								We'll make time to interact with you!
							</div>
							<div className="mt-4 mb-5">
								<Form className="lets-connect-form">
									<FloatingLabel
										controlId="floatingInput"
										label="Your name"
										className="mb-3 col-md-6"
									>
										<Form.Control
											type="text"
											placeholder="Your name"
											onChange={(e) => setFullName(e.target.value)}
										/>
										<p className="sectionSMHead">
											{error.fullname ? error.fullname : ""}
										</p>
									</FloatingLabel>
									<FloatingLabel
										controlId="floatingInput"
										label="Your email"
										className="mb-3 col-md-9"
									>
										<Form.Control
											type="email"
											placeholder="Your email"
											onChange={(e) => setEmail(e.target.value)}
										/>
										<p className="sectionSMHead">
											{error.email ? error.email : ""}
										</p>
									</FloatingLabel>
									<FloatingLabel
										controlId="floatingInput"
										label="What are you looking for?"
										className="mb-3"
									>
										<Form.Control
											as="textarea"
											rows={5}
											placeholder="What are you looking for?"
											style={{ height: "125px" }}
											onChange={(e) => setLookingFor(e.target.value)}
										/>
										<p className="sectionSMHead">
											{error.lookingfor ? error.lookingfor : ""}
										</p>
									</FloatingLabel>
									<div className="submitbutton">
										<Button
											variant="danger"
											size="lg"
											type="submit"
											disabled={isDisabled}
											onClick={onSubmit}
										>
											Sent Inquiry
										</Button>
									</div>
								</Form>
							</div>
						</Col>
						<Col
							lg={{ span: 5, offset: 2 }}
							md={{ span: 5, offset: 2 }}
							sm={{ span: 5, offset: 2 }}
							xs={12}
						>
							<div className="contact-info-wrapper">
								<div
									className="contact-info-section"
									style={{ backgroundImage: `url(${LOGO_BIG})` }}
								></div>
								<Card className="contact-card">
									<Card.Body>
										<div className="social-icons">
											<div className="d-flex justify-content-between mb-xl-5">
												<div className="">
													<a href={SKYPE_URL} target="_blank" rel="noreferrer">
														<img src={SKYPE_ICON} alt="skype" />
													</a>
												</div>
												<div className="">
													<a
														href={LINKEDIN_URL}
														target="_blank"
														rel="noreferrer"
													>
														<img src={LINKEDIN_ICON} alt="linkedin" />
													</a>
												</div>
												<div className="">
													<a
														href={FACEBOOK_URL}
														target="_blank"
														rel="noreferrer"
													>
														<img src={FACEBOOK_ICON} alt="facebook" />
													</a>
												</div>
												<div className="">
													<a
														href={TWITTER_URL}
														target="_blank"
														rel="noreferrer"
													>
														<img
															src={TWITTER_ICON}
															alt="twitter"
															width="30"
															height="30"
															style={{ marginTop: "5px" }}
														/>
													</a>
												</div>
												<div className="">
													<a href={INSTA_URL} target="_blank" rel="noreferrer">
														<img src={INSTAGRAM_ICON} alt="instagram" />
													</a>
												</div>
											</div>
										</div>
										<div className="email-section">
											<p>Email</p>
											<p>Info@nxinfoway.com</p>
										</div>
										<div className="contact-section">
											<p>Call</p>
											<p>+91 982 591 0808</p>
											<p>
												<span>Availability:&nbsp;</span>IST 8 am TO 11 pm
											</p>
										</div>
										<div className="logosm mt-xl-5">
											<img
												src={LOGO_NAVIGATION}
												alt="logo-sm"
												width="50"
												height="50"
											/>
										</div>
										<div className="copy-right mt-2 d-none d-sm-block">
											<p className="m-0">&copy; {CURRENT_YEAR} nxinfoway.</p>
											<p className="m-0">All rights reserved.</p>
										</div>
										<div className="copy-right mt-2 d-block d-sm-none">
											<p className="m-0">
												&copy; {CURRENT_YEAR} nxinfoway. All rights reserved.
											</p>
										</div>
									</Card.Body>
								</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="section darkbg flinks">
				<Container fluid="xl">
					<div className="footer-links">
						<FooterLinks />
					</div>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Footer;
