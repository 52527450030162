import React from "react";

import { Row, Col, Container, Navbar, Nav, Image } from "react-bootstrap";
import { SocialIconMainMenu } from "../components";

import LOGO from "../assets/images/LogoNavigation.svg";
import CLOSE_ICON from "../assets/images/icon-close-black.svg";
import LOGOBLACK from "../assets/images/logo/logo-black@2x.png";
import LOGOMARK from "../assets/images/LogoMark@2x.png";

const MainMenu = (props) => {
	const { click, closeClick } = props;

	return (
		<div id="myNav" className="overlay" style={{ height: click }}>
			<Navbar expand="lg">
				<Container fluid="xl" className="py-1 main-menu">
					<Navbar.Brand href="/">
						<img
							src={LOGOBLACK}
							alt="logo"
							height="23"
							className="d-none d-sm-block"
						/>
						<img
							src={LOGO}
							alt="logo"
							height="35"
							className="d-block d-sm-none"
						/>
					</Navbar.Brand>
					<div className="d-block d-sm-none m-auto my-lg-0">
						<Nav.Link href="#" className="nav-item-text-black">
							Info@nxinfoway.com
						</Nav.Link>
					</div>
					<Nav
						className="d-flex menu-nav d-block d-sm-none"
						style={{ maxHeight: "100px" }}
						navbarScroll
					>
						<Nav.Link href="#" onClick={closeClick}>
							<img src={CLOSE_ICON} alt="menu-icon" />
						</Nav.Link>
					</Nav>
					<Navbar.Toggle aria-controls="navbarScroll" className="d-none" />
					<Navbar.Collapse id="navbarScroll" className="d-none d-sm-block">
						<div className="m-auto my-2 my-lg-0">
							<a href="/">
								<img src={LOGO} alt="logo-main" />
							</a>
						</div>
						<Nav
							className="d-flex menu-nav"
							style={{ maxHeight: "100px" }}
							navbarScroll
						>
							<Nav.Link href="#" className="nav-item-text-black">
								Info@nxinfoway.com
							</Nav.Link>
							<Nav.Link href="#" onClick={closeClick}>
								<img src={CLOSE_ICON} alt="menu-icon" />
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Container fluid="xl">
				<Row>
					<Col lg={8} md={8} sm={8} xs={12} className="d-none d-sm-block">
						<div style={{ width: "526px", height: "479px", margin: "0 auto" }}>
							<Image src={LOGOMARK} fluid />
						</div>
					</Col>
					<Col lg={4} md={4} sm={4} xs={12}>
						<div className="menu-section">
							<ul className="list-unstyled mb-0">
								<li>
									<a href="/" onClick={closeClick}>
										Home
									</a>
								</li>
								<li>
									<a href="/services" onClick={closeClick}>
										Services
									</a>
								</li>
								<li>
									<a href="/our-work" onClick={closeClick}>
										Work
									</a>
								</li>
								<li>
									<a href="/blogs">Blogs</a>
								</li>
								<li>
									<a href="/about" onClick={closeClick}>
										About
									</a>
								</li>
								<li>
									<a href="/contact" onClick={closeClick}>
										Contact
									</a>
								</li>
							</ul>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg={12} md={12} sm={12} xs={12}>
						{/* mobile view */}
						<div className="d-block d-sm-none">
							<ul className="list-inline m-0 mainmenu social-icons-list">
								<SocialIconMainMenu />
							</ul>
						</div>
						{/* desktop view */}
						<div className="d-none d-sm-block">
							<ul className="list-inline text-end m-0 mainmenu">
								<SocialIconMainMenu />
							</ul>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default MainMenu;
