import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Row, Col, Container, Card, Button, Image } from "react-bootstrap";

import { Layout, Footer, LetsCraftSection } from "../components";
import { allProducts } from "../utils/productData";
import { DEFAULT } from "../utils/keyWords";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
export const DOMAIN = "https://www.nxinfoway.com/";

const Product = () => {
	const { slug } = useParams();
	const [initialData, setInitialData] = useState({});
	const [maxHeight, setmaxHeight] = useState();
	const [maxHeightFeat, setMaxHeightFeat] = useState();

	const getData = () => {
		const data = allProducts.find((item) => item.slug === slug);
		setInitialData(data);

		let benefitsHeight = document.getElementsByClassName("carddata");
		let maxheightArray = [];
		for (let a = 0; a < benefitsHeight.length; a++) {
			maxheightArray.push(benefitsHeight[a].offsetHeight);
		}
		setmaxHeight((maxHeight) => Math.max(...maxheightArray));

		let cardfeatHeight = document.getElementsByClassName("cardfeat");
		let cardfeatheightArray = [];
		for (let a = 0; a < cardfeatHeight.length; a++) {
			cardfeatheightArray.push(cardfeatHeight[a].offsetHeight);
		}
		setMaxHeightFeat((maxHeightFeat) => Math.max(...cardfeatheightArray));
	};

	useEffect(() => getData());

	ReactGA.pageview(initialData.gaview);

	return (
		<React.Fragment>
			<Helmet>
				<title>
				Custom Mobile and Website Development Company and Services India and USA | Nxinfoway
				</title>
				<meta
					name="description"
					content="Nxinfoway portfolio consists many projects developed in Android, IOS, Larawel, WordPress, UI and UX, Node.JS, Magento, PHP ASP.NET, AngularJS, ReactJS etc."
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="web development portfolio, mobile app development portfolio, web design and development, graphics designing work"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="Custom Mobile and Website Development Company and Services India and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Nxinfoway portfolio consists many projects developed in Android, IOS, Larawel, WordPress, UI and UX, Node.JS, Magento, PHP ASP.NET, AngularJS, ReactJS etc."
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content="Custom Mobile and Website Development Company and Services India and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Nxinfoway portfolio consists many projects developed in Android, IOS, Larawel, WordPress, UI and UX, Node.JS, Magento, PHP ASP.NET, AngularJS, ReactJS etc."
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Digital Marketing Agency,Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />


			</Helmet>
		<Layout>
			<div className="section project service head">
				<Container fluid="xl">
					<div className="wrapper">
						<Row>
							<Col xl={2} lg={2} md={2} sm={2} xs={12}>
								<Image
									src={initialData.logo}
									alt={initialData.name}
									className="project-logo"
								/>
							</Col>
							<Col xl={6} lg={6} md={6} sm={6} xs={12}>
								<h1>{initialData.name}</h1>
								<p className="title">{initialData.mainParagraph}</p>
							</Col>
							<Col
								xl={{ span: 3, offset: 1 }}
								lg={{ span: 3, offset: 1 }}
								md={{ span: 3, offset: 1 }}
								sm={{ span: 3, offset: 1 }}
								xs={12}
							>
								<ul className="list-unstyled technologyUsed m-0">
									{initialData.technologies &&
										initialData.technologies.map((item, index) => {
											return <li key={index}>— {item}</li>;
										})}
								</ul>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<div className="section product-banner">
				<Image src={initialData.banner} alt="product-banner" fluid />
			</div>
			<div className="section service project-detail">
				<Container fluid="xl">
					<div className="wrapper">
						<Row>
							<Col
								xl={{ span: 8, offset: 2 }}
								lg={{ span: 8, offset: 2 }}
								md={{ span: 8, offset: 2 }}
								sm={{ span: 8, offset: 2 }}
								xs={12}
							>
								<h2 className="mb-4">{initialData.heading1}</h2>
								{initialData.content1 &&
									initialData.content1.map((item, index) => {
										return (
											<p
												className={`title ${
													initialData.content1.length - 1 === index
														? "pb-lg-5"
														: ""
												}`}
												key={index}
											>
												{item}
											</p>
										);
									})}
							</Col>
						</Row>
						<Row className="mt-3">
							{initialData.badges &&
								initialData.badges.map((item, index) => {
									return (
										<Col
											xl={6}
											lg={6}
											md={6}
											sm={6}
											xs={12}
											key={index}
											className="mb-3"
										>
											<Card
												className="carddata"
												style={{ height: `${maxHeight}px` }}
											>
												<Card.Body className="text-center">
													<ul className="list-inline mb-2">
														<li className="list-inline-item">
															<Card.Title className="mt-1">
																{item.header}
															</Card.Title>
														</li>
													</ul>
													<ul className="list-inline mb-0 tags">
														{item.items &&
															item.items.map((item, index) => {
																return (
																	<li className="list-inline-item" key={index}>
																		<a href="">{item}</a>
																	</li>
																);
															})}
													</ul>
												</Card.Body>
											</Card>
										</Col>
									);
								})}
						</Row>
					</div>
				</Container>
			</div>
			<div className="section project service">
				<Container fluid="xl">
					<div className="wrapper">
						<Row>
							<Col
								xl={{ span: 8, offset: 2 }}
								lg={{ span: 8, offset: 2 }}
								md={{ span: 8, offset: 2 }}
								sm={{ span: 8, offset: 2 }}
								xs={12}
							>
								<h2 className="mb-lg-4 project-h1">Features</h2>
								<p className="title">{initialData.featureParagraph}</p>
							</Col>
						</Row>
						<Row className="mt-lg-5 mt-4">
							{initialData.features &&
								initialData.features.map((item, index) => {
									return (
										<Col
											lg={6}
											md={6}
											sm={6}
											xs={12}
											key={index}
											className="mb-3"
										>
											<Card className={`card-feature-${index}`}>
												<Card.Body
													className={`px-lg-5 cardfeat`}
													style={{
														height: index !== 2 ? `${maxHeightFeat}px` : "auto",
													}}
												>
													<ul className="list-inline mb-2">
														<li className="list-inline-item">
															<Card.Title>{item.header}</Card.Title>
														</li>
													</ul>
													<ul className="list-unstyled mb-0 features">
														{item.items &&
															item.items.map((item, index) => {
																return <li key={index}>- {item}</li>;
															})}
													</ul>
												</Card.Body>
											</Card>
										</Col>
									);
								})}
						</Row>
					</div>
				</Container>
			</div>
			<div className="section project service">
				<Container fluid="xl">
					<div className="wrapper">
						<Row>
							<Col
								xl={{ span: 8, offset: 2 }}
								lg={{ span: 8, offset: 2 }}
								md={{ span: 8, offset: 2 }}
								sm={{ span: 8, offset: 2 }}
								xs={12}
							>
								<h2 className="mb-lg-4 project-h1">Visual insight</h2>
								<p className="title">
									Explore some beauties from fashion store’s minimal user
									interface build for ecommerce store experience.
								</p>
							</Col>
						</Row>
					</div>
				</Container>
				<div className="mt-5 visual-insights">
					{initialData.visualInsights &&
						initialData.visualInsights.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<p className="text-center mb-1 mt-3">{item.header}</p>
									<Image src={item.imgSrc} alt={item.header} fluid />
								</React.Fragment>
							);
						})}
				</div>
			</div>
			<div className="section project service">
				<Container fluid="xl">
					<div className="wrapper text-center">
						<Button
							href="/contact"
							type="button"
							size="lg"
							variant="danger"
							className="quote"
						>
							Contact Us
						</Button>
					</div>
					<div className="wrapper">
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="simple-tags text-center">
									{DEFAULT &&
										DEFAULT.map((item, index) => {
											return <span key={index}>{item}</span>;
										})}
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<div className="section project service">
				<Container fluid="xl">
					<div className="wrapper">
						<LetsCraftSection />
					</div>
				</Container>
			</div>
			<Footer />
		</Layout>
		</React.Fragment>
	);
};

export default Product;
