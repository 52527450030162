import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import moment from "moment";

import { Helmet } from "react-helmet";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Layout, Footer, LetsCraftSection } from "../components";
import ReactGA from "react-ga";
import { BLOG_DETAIL_URL, blogsData, DOMAIN, ldJson } from "../utils/constants";

const BlogDetail = (props) => {
	const { slug } = useParams();
	const [blog, setBlog] = useState({});

	const htmlDecode = (data) => {
		var e = document.createElement("div");
		e.innerHTML = data;
		return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
	};

	const getBlogDetail = (slug) => {
		fetch(`${BLOG_DETAIL_URL}${slug}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setBlog(data.data[0]);
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		getBlogDetail(slug);
	}, []);

	ReactGA.pageview(blog.title);

	return (
		<React.Fragment>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
				<title>{blog.title}</title>
				<meta
					name="description"
					content="Nxinfoway leading mobile, web design &amp; website development company in Los Angeles, San Francisco, San Jose, and all of California. We delivers custom website design, UI &amp; UX, responsive design, Android, iPhone, ReactNative, Flutter development, SEO optimized websites. Choice of CMS-WordPress, NodeJS, frameworks-React, Angular, Vue and built on ASP.Net &amp; PHP platforms."
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="mobile application development company India, PHP web development company, iPhone apps development, mobile app development, android app development"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content={blog.title}
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Nxinfoway leading mobile, web design &amp; website development company in Los Angeles, San Francisco, San Jose, and all of California. We delivers custom website design, UI &amp; UX, responsive design, Android, iPhone, ReactNative, Flutter development, SEO optimized websites. Choice of CMS-WordPress, NodeJS, frameworks-React, Angular, Vue and built on ASP.Net &amp; PHP platforms."
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content={blog.title}
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Nxinfoway leading mobile, web design &amp; website development company in Los Angeles, San Francisco, San Jose, and all of California. We delivers custom website design, UI &amp; UX, responsive design, Android, iPhone, ReactNative, Flutter development, SEO optimized websites. Choice of CMS-WordPress, NodeJS, frameworks-React, Angular, Vue and built on ASP.Net &amp; PHP platforms."
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />
			</Helmet>
			<Layout>
				<div className="section service head blog">
					<Container fluid="xl">
						<div className="wrapper">
							<Row>
								<Col
									lg={12}
									md={12}
									sm={12}
									xs={12}
									className="mb-4 blog-detail"
								>
									<Card>
										<Card.Img variant="top" src={blog.mainImage} fluid />
										<Card.Body>
											<Card.Title className="blog-date fw-bold">
												{moment(blog.publishedAt).format("DD MMM YYYY")}
											</Card.Title>
											<Card.Title className="blog-title fw-bold mb-3">
												<h1>{blog.title}</h1>
											</Card.Title>
											<Card.Text
												dangerouslySetInnerHTML={{
													__html: htmlDecode(blog.content),
												}}
											/>
											<small className="blog-time">
												{blog.minread} min. read
											</small>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<LetsCraftSection />
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default BlogDetail;
