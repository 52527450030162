import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

import { HOW_WE_DO_CARDS } from '../utils/constants';

const HowWeDo = (props) => {

    return (
        <Row className="mt-4 howwedo">
            {HOW_WE_DO_CARDS && HOW_WE_DO_CARDS.map((item, index) => {
                return (
                    <Col lg={3} md={3} sm={6} xs={6} key={index}>
                        <Card>
                            <Card.Body className="text-center">
                                <Fade bottom duration={1500}>
                                    <Card.Img variant="top" src={item.image} />
                                </Fade>
                                <Card.Title className="pt-2">{item.header}</Card.Title>
                                <Card.Text>{item.headerSm}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            })}
        </Row>
    )
}

export default HowWeDo
