export const DEFAULT = ["iphone development", "iphone app development", "iOS development", "mobile application development", "custom mobile application", "swift development", "objective-c development", "native mobile app", "iphone app development company", "ios development company in india", "ios development in india", "iphone app development in india", "acadamy", "best mobile app development in india"];

export const CONTACT_PAGE = ["iphone development", "iphone app development", "iOS development", "mobile application development", "custom mobile application", "swift development", "objective-c development", "native mobile app", "iphone app development company", "ios development company in india", "ios development in india", "iphone app development in india", "acadamy", "best mobile app development in india"];

export const SERVICES_PAGE = ["iphone development", "iphone app development", "iOS development", "mobile application development", "custom mobile application", "swift development", "objective-c development", "native mobile app", "iphone app development company", "ios development company in india", "ios development in india", "iphone app development in india", "acadamy", "best mobile app development in india"];

export const ABOUT_PAGE = ["iphone development", "iphone app development", "iOS development", "mobile application development", "custom mobile application", "androdi development", "php development", "native mobile app", "android app development company", "ios development company in india", "ios development in india", "iphone app development in india", "hire php developer", "hire android developer","hire Iphoen developer","hire nodeJS developer","hire ReactJS developer","hire wordpress developer","hire magento developer","hire angular developer","hire UI&UX developer"];

export const ANDROID_PAGE = ["android development", "android app development", "android app UI/UX design", "mobile application development", "custom mobile application", "kotlin development", "java development", "native mobile app", "android app development company", "android development company in india", "android development in india", "android app development in india", "acadamy", "best mobile app development in india", "best android app development in india","Kotlin app development","Android app consultation","Android app consultation in india","Native Android App Development","Android App Maintenance","Android App Design","top mobile development company in india","top mobile development company in US","top mobile development company in Uk","Android dedicated developer","hire android dedicated developer","hire android app dedicated developer","hire dedicated android developer","hire android developer","hire mobile developer"];

export const LARAVEL_PAGE = ["laravel development", "laravel web development", "Laravel Web application Development", "web development", "custom web application", "php development", "Laravel Customization & Integration", "Laravel Template Design and Development", "laravel web development company", "laravel development company in india", "laravel development in india", "laravel web development in india", "Laravel Extension Development", "best laravel web development in india","E-commerce Development","Application Re-Engineering and Enhancement","Laravel package development"];

export const PHP_PAGE = ["php development", "php web development", "php Web application Development", "web development", "custom web application", "php development", "php Customization & Integration", "php template Design and Development", "php web development company", "php development company in india", "php development in india", "php web development in india", "php Extension Development", "best php web development in india","php E-commerce Development","Application Re-Engineering and Enhancement","php package development","php rest api integration development company"];

export const NODEJS_PAGE = ["Node.JS development", "Node.JS web development", "Node.JS Web application Development", "Node.JS development", "custom Node.JS application", "Node.JS web and mobile development", "Node.JS Customization & Integration", "Node.JS template Design and Development", "Node.JS web development company", "Node.JS development company in india", "Node.JS development in india", "Node.JS web development in india", "Node.JS Extension Development", "best Node.JS web development in india","Node.JS E-commerce Development","Application Re-Engineering and Enhancement","Node.JS package development","Node.JS rest api integration development company","Node.JS rest api integration"];

export const REACTJS_PAGE = ["ReactJS development", "ReactJS web development", "ReactJS front-end application Development", "ReactJS development", "custom ReactJS application", "ReactJS web and mobile UI development", "ReactJS Customization & Integration", "ReactJS template Design and Development", "ReactJS frontend development company", "ReactJS development company in india", "ReactJS development in india", "ReactJS web development in india", "ReactJS front-end development in india", "best ReactsJs web development in india","best ReactsJs web design development in india","ReactJs Re-Engineering and Enhancement","ReactJs package development","ReactJS web design development company","ReactJs UI development company in india","ReactJs template design company in india","ReactJs template design","ReactJs website design company","ReactJs website design company in india","ReactJs website design company india"];

export const ANGULARJS_PAGE = ["AngularJS development", "AngularJS web development", "AngularJS front-end application Development", "AngularJS development", "custom AngularJS application", "AngularJS web and mobile UI development", "AngularJS Customization & Integration", "AngularJS template Design and Development", "AngularJS frontend development company", "AngularJS development company in india", "AngularJS development in india", "AngularJS web development in india", "AngularJS front-end development in india", "best AngularJS web development in india","best AngularJS web design development in india","AngularJS Re-Engineering and Enhancement","AngularJS package development","AngularJS web design development company","AngularJS UI development company in india","AngularJS template design company in india","AngularJS template design","AngularJS website design company","AngularJS website design company in india","AngularJS website design company india"];

export const CODEINTER_PAGE = ["CodeIgniter development", "CodeIgniter web development", "CodeIgniter Web application Development", "CodeIgniter development", "custom web application", "php development", "CodeIgniter Customization & Integration", "CodeIgniter Template Design and Development", "CodeIgniter web development company", "CodeIgniter development company in india", "CodeIgniter development in india", "CodeIgniter web development in india", "CodeIgniter Extension Development", "best CodeIgniter web development in india","E-commerce Development","Application Re-Engineering and Enhancement","CodeIgniter package development"];

export const WORDPRESS_PAGE = ["Wordpress development", "Wordpress web development", "Wordpress Web application Development", "Wordpress development", " Wordpress custom web application", "php development", "Wordpress Customization & Integration", "Wordpress Template Design and Development", "Wordpress web development company", "Wordpress development company in india", "Wordpress development in india", "Wordpress web development in india", "Wordpress Extension Development", "best Wordpress web development in india","Wordpress E-commerce Development","Wordpress Application Re-Engineering and Enhancement","Wordpress blog development comapny in india","Wordpress website development comapny in india","Wordpress plugin development comapny in india","Wordpress theme development comapny in india","Wordpress CMS development comapny in india"];

export const MAGENTO_PAGE = ["Magento development", "Magento web development", "Magento Web application Development", "Magento CMS development", " Magento custom CMS application", "Magento ecommerce development", "Magento CMS Customization & Integration", "Magento Template Design and Development", "Magento web development company", "Magento development company in india", "Magento development in india", "Magento web development in india", "Magento Extension Development", "best Magento development in india","Magento E-commerce Development","Magento Application Re-Engineering and Enhancement","Magento CMS development comapny in india","Magento website development comapny in india","Magento plugin development comapny in india","Magento Ecommrece CMS development comapny in india","Magento CMS development comapny in india"];

export const REACTNATIVE_PAGE = ["React-Native development", "React-Native mobile development", "React-Native mobile application Development", "React-Native android and ios development", " React-Native custom mbbile application", "React-Native ecommerce development", "React-Native android & Ios Customization & Integration", "React-Native Template Design and Development", "React-Native mobile development company", "React-Native development company in india", "React-Native development in india", "React-Native mobile development in india", "React-Native crosplateform Development", "best React-Native development in india","React-Native E-commerce Development","React-Native Application Re-Engineering and Enhancement","React-Native IOS/Android development comapny in india","React-Native crosplatform development comapny in india","React-Native android development comapny in india"];

export const FLUTTER_PAGE = ["Flutter development", "Flutter mobile development", "Flutter mobile application Development", "Flutter android and ios development", "Flutter custom mbbile application", "Flutter ecommerce development", "Flutter android & Ios Customization & Integration", "Hire Flutter developer", "Flutter mobile development company", "Flutter development company in india", "Flutter development in india", "hire Flutter mobile development in india", "hire Flutter crosplateform Developer", "best Flutter development in india","hire Flutter E-commerce developer","Flutter Application Re-Engineering and Enhancement","Flutter IOS/Android development comapny in india","Flutter crosplatform development comapny in india","Flutter android development comapny in india"];

export const JAVA_PAGE = ["java development", "hire java developer", "Java application Development", "ERP developer", "ERP application", "CRM development", "ERP Customization & Integration", "Hire java/Jsp developer", "hire spring developer", "java development company in india", "java development in india", "hire ERP development in india", "hire ERP Developer", "best Java development in india","hire java E-commerce developer","java Application Re-Engineering and Enhancement","java ERP/CRM development comapny in india","JSP development comapny in india","Spring framework development comapny in india","hire CRM developer"];

export const ASP_PAGE = [".net development", "hire .net developer", ".net application Development", "ERP developer", "ERP application", "CRM development", "ERP Customization & Integration", "Hire ASP.NET developer", "hire VB>NET developer", ".net development company in india", ".Net development in india", "hire ERP development in india", "hire ERP Developer", "best .net development in india","hire ASP.NET E-commerce developer",".Net Application Re-Engineering and Enhancement",".Net ERP/CRM development comapny in india","ASP.NET development comapny in india","VB.NET framework development comapny in india","hire CRM developer"];
