import React from 'react';

import { Button } from 'react-bootstrap';

const ProductMainButton = (props) => {
    const { isLink, label } = props;
    
    return (
        <div className="viewbutton">
            {
                isLink ?
                    <Button href="/our-work" size="lg" variant="outline-light" className="main-button mb-3">{label}</Button>
                :
                    <Button type="button" size="lg" variant="outline-light" className="main-button mb-3">{label}</Button>
            }
        </div>
    )
}

export default ProductMainButton
