import React, { useRef, useEffect, useState, useContext } from "react";
import ReactGA from "react-ga";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { useParams, Navigate, Link } from "react-router-dom";
import { Row, Col, Container, Image, Card, Button } from "react-bootstrap";

import BlogContext from "../context/blogContext";
import {
	Layout,
	LetsCraftSection,
	Footer,
	HowWeDo,
	BlogSection,
} from "../components";
import { COMPANYLOGO, ldJson, DOMAIN } from "../utils/constants";
import { DEVELOPMENT_DATA } from "../utils/developmentData";
import {
	DEFAULT,
	ANDROID_PAGE,
	LARAVEL_PAGE,
	PHP_PAGE,
	NODEJS_PAGE,
	REACTJS_PAGE,
	ANGULARJS_PAGE,
	CODEINTER_PAGE,
	WORDPRESS_PAGE,
	MAGENTO_PAGE,
	REACTNATIVE_PAGE,
	FLUTTER_PAGE,
	JAVA_PAGE,
	ASP_PAGE,
} from "../utils/keyWords";

import CHECK_DESIGN from "../assets/images/ios-devlopment/check-design@2x.png";
import ICON1 from "../assets/images/ios-devlopment/icon1.png";
import ICON2 from "../assets/images/ios-devlopment/Icon2.png";
import ICON3 from "../assets/images/ios-devlopment/Icon3.png";
import ICON4 from "../assets/images/ios-devlopment/Icon4.png";
import DOWNARROW from "../assets/images/ios-devlopment/down-arrow.png";
import BENEFIT_ICON from "../assets/images/ios-devlopment/benefit-icon.png";
import PHONE_ICON from "../assets/images/ios-devlopment/phone-icon@2x.png";
import CLIENT_VIDEO from "../assets/images/ios-devlopment/client-video-1@2x.png";
import CLIENT_VIDEO1 from "../assets/images/ios-devlopment/client-video-2@2x.png";
import MOBILE_BG from "../assets/images/mobile-bg@2x.png";
import HIRE_ICON from "../assets/images/ios-devlopment/hire-icon@2x.png";

const leftSettings = {
	dots: false,
	infinite: true,
	autoplay: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	speed: 4000,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	variableWidth: true,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const Development = () => {
	const { slug } = useParams();
	const { blogs } = useContext(BlogContext);

	const [initialData, setInitialData] = useState({});
	const [maxHeight, setmaxHeight] = useState();
	const [keyWord, setKeyWord] = useState([]);

	const getData = () => {
		const data = DEVELOPMENT_DATA.find((item) => item.slug === slug);
		switch (slug) {
			case "android-development":
				setKeyWord(ANDROID_PAGE);
				break;
			case "php-development":
				setKeyWord(PHP_PAGE);
				break;
			case "laravel-development":
				setKeyWord(LARAVEL_PAGE);
				break;
			case "nodejs-development":
				setKeyWord(NODEJS_PAGE);
				break;
			case "reactjs-development":
				setKeyWord(REACTJS_PAGE);
				break;
			case "angularjs-development":
				setKeyWord(ANGULARJS_PAGE);
				break;
			case "codeigniter-development":
				setKeyWord(CODEINTER_PAGE);
				break;
			case "wordpress-development":
				setKeyWord(WORDPRESS_PAGE);
				break;
			case "magento-development":
				setKeyWord(MAGENTO_PAGE);
				break;
			case "reactnative-development":
				setKeyWord(REACTNATIVE_PAGE);
				break;
			case "flutter-development":
				setKeyWord(FLUTTER_PAGE);
				break;
			case "java-development":
				setKeyWord(JAVA_PAGE);
				break;
			case "dot-net-development":
				setKeyWord(ASP_PAGE);
				break;
			default:
				setKeyWord(DEFAULT);
				break;
		}
		setInitialData(data);

		let benefitsHeight = document.getElementsByClassName("benefits");
		let maxheightArray = [];
		for (let a = 0; a < benefitsHeight.length; a++) {
			maxheightArray.push(benefitsHeight[a].offsetHeight);
		}
		setmaxHeight((maxHeight) => Math.max(...maxheightArray));
	};

	useEffect(() => getData());

	ReactGA.pageview(initialData?.gaView);

	if (initialData === undefined || initialData === null) {
		return <Navigate to="/404" />;
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>{initialData?.pageTitle}</title>
				<meta
					name="description"
					content={initialData?.pageDescription}
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content={initialData?.pageKeyWords}
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content={initialData?.pageTitle}
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content={initialData?.pageDescription}
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content={initialData?.pageTitle}
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content={initialData?.pageDescription}
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />
				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
			</Helmet>
			<Layout>
				<div className="section our-work head">
					<Container fluid="xl">
						<div className="upper-section">
							<div className="d-none d-sm-block text-center">
								<Image
									src={CHECK_DESIGN}
									height="74"
									alt={initialData?.pageKeyWords}
									style={{ margin: "15px 0px" }}
								/>
							</div>
							<div className="text-center">
								<Image
									src={initialData?.mainIcon}
									height="100"
									alt={initialData?.pageKeyWords}
								/>
							</div>
							<h1 className="mt-4 mb-3 text-center">
								{initialData?.mainHeader}
							</h1>
							<Row>
								<Col
									lg={{ span: 6, offset: 3 }}
									md={{ span: 6, offset: 3 }}
									sm={{ span: 6, offset: 3 }}
									xs={12}
								>
									<p className="title mb-84 text-center">
										{initialData?.subHeader}
									</p>
								</Col>
							</Row>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="why-us">
										<ul className="list-inline m-0">
											<li
												className="list-inline-item me-xl-4 d-none d-sm-inline-block"
												style={{ display: "inline-block" }}
											>
												why us?{" "}
											</li>
											<li className="list-inline-item mb-3 mb-lg-0">
												<Image src={ICON1} /> Dedicated Support
											</li>
											<li className="list-inline-item mb-3 mb-lg-0">
												<Image src={ICON2} /> Technology Expertise
											</li>
											<li className="list-inline-item mb-3 mb-lg-0">
												<Image src={ICON3} /> Competitive Pricing
											</li>
											<li className="list-inline-item mb-3 mb-lg-0">
												<Image src={ICON4} /> Integrity & Transparency
											</li>
										</ul>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="text-center my-4">
										<Image src={DOWNARROW} alt="down-arrow" />
									</div>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="why-iphone">
					<Container fluid="xl">
						<div className="content">
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<h2>{initialData?.whyHeader}</h2>
									{initialData?.whyContent &&
										initialData?.whyContent.map((item, index) => {
											return (
												<p
													className={
														initialData?.whyContent.length === index + 1
															? "mb-0"
															: "mb-3"
													}
													key={index}
												>
													{item}
												</p>
											);
										})}
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="benefits-ios">
					<Container fluid="xl">
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<h2>{initialData?.benefitHeader}</h2>
								<p className="mb-xl-5">{initialData?.benefitContent}</p>
							</Col>
						</Row>
						<Row className="benefits-items g-2 g-lg-4">
							{initialData?.beneFits &&
								initialData?.beneFits.map((item, index) => {
									return (
										<Col lg={3} md={3} sm={3} xs={6} key={index}>
											<Card
												className="benefits"
												style={{ height: `${maxHeight}px` }}
											>
												<Card.Body>
													<Image src={BENEFIT_ICON} alt="benefit-icon" />
													<h3>{item.heading}</h3>
													<p className="d-none d-sm-block">{item.content}</p>
												</Card.Body>
											</Card>
										</Col>
									);
								})}
						</Row>
						<div className="text-center want-discuss">
							<a href="/contact">Contact to Learn More</a>
						</div>
					</Container>
				</div>
				<div className="ios-service-offer">
					<Container fluid="xl">
						<div className="content">
							<h2>{initialData?.serviceOfferHeader}</h2>
							<p>{initialData?.serviceOfferContent}</p>
							<Row>
								{initialData?.servicesOffer &&
									initialData?.servicesOffer.map((item, index) => {
										return (
											<Col lg={6} md={6} sm={6} xs={12} key={index}>
												<div className="service-offers">
													<Image src={PHONE_ICON} />
													<h3>{item.heaing}</h3>
													<p>{item.content}</p>
												</div>
											</Col>
										);
									})}
							</Row>
							<div className="text-center want-discuss">
								<a href="/contact">Want to Discuss Project?</a>
							</div>
						</div>
					</Container>
				</div>
				<div className="feedback-section">
					<Container fluid="xl">
						<h3 className="text-center mb-xl-5 mb-4">Feedbacks</h3>
						<Row>
							<Col lg={6} md={6} sm={6} xs={12} className="mb-3">
								<div className="feedback-video">
									<a href="https://youtu.be/ASQqKUtxEow" target="_blank">
										<Image src={CLIENT_VIDEO} fluid />
									</a>
								</div>
							</Col>
							<Col lg={6} md={6} sm={6} xs={12} className="mb-3">
								<div className="feedback-video">
									<a href="https://youtu.be/DDvpxHxcxTw" target="_blank">
										<Image src={CLIENT_VIDEO1} fluid />
									</a>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="section darkbg development-process">
					<Container fluid="xl">
						<h3>{initialData?.processHeader}</h3>
						<HowWeDo />
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div
									className="explore-section"
									style={{
										background: `url(${MOBILE_BG}) no-repeat`,
										backgroundPosition: "center center",
										backgroundSize: "cover",
									}}
								>
									<Button
										href="/our-work"
										size="lg"
										variant="light"
										className="explore"
										style={{ margin: "17px 0px", border: "0px" }}
									>
										Explore Work
									</Button>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="feedback-section">
					<Container fluid="xl">
						<h3 className="text-center mb-xl-5 mb-4">Blogs</h3>
						<Row>
							{blogs &&
								blogs
									.reverse()
									.slice(0, 2)
									.map((item, index) => {
										return (
											<Col
												lg={6}
												md={6}
												sm={6}
												xs={12}
												key={index}
												className="mb-3 blog-section"
											>
												<BlogSection item={item} />
											</Col>
										);
									})}
						</Row>
						<Row>
							<Col lg={12} md={12} sm={12} xs={12} className="my-4 text-center">
								<Button
									href="/blogs"
									size="lg"
									variant="light"
									className="explore"
									style={{ margin: "17px 0px", border: "0px" }}
								>
									Read More
								</Button>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="hire-section">
					<Container fluid="xl">
						<div className="content">
							<div className="clearfix d-none d-sm-block">
								<div className="float-start">
									<ul className="list-inline mb-0">
										<li className="list-inline-item">
											<Image
												src={HIRE_ICON}
												alt="hire section"
												width="76"
												height="76"
												style={{ marginTop: "-70px" }}
											/>
										</li>
										<li className="list-inline-item ms-2">
											<h3>
												Want to hire our
												<br />
												{initialData?.hireHeader}?
											</h3>
										</li>
									</ul>
								</div>
								<div className="float-end">
									<Button href="/contact" variant="danger" size="lg">
										Contact Now
									</Button>
								</div>
							</div>
							<div className="d-block d-sm-none">
								<ul className="list-unstyled text-center m-0">
									<li>
										<Image
											src={HIRE_ICON}
											alt="hire section"
											width="76"
											height="76"
										/>
									</li>
									<li className="mt-3">
										<h3>
											Want to hire our
											<br />
											{initialData?.hireHeader}?
										</h3>
									</li>
									<li className="mt-5">
										<Button href="/contact" variant="danger" size="lg">
											Contact Now
										</Button>
									</li>
								</ul>
							</div>
						</div>
					</Container>
				</div>
				<div className="company-logos">
					<Container fluid>
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<Slider {...leftSettings}>
									{COMPANYLOGO &&
										COMPANYLOGO.map((item, index) => {
											return (
												<div className="" key={index}>
													<img src={item} alt={item} width="260" height="120" />
												</div>
											);
										})}
								</Slider>
							</Col>
						</Row>
					</Container>
					<Container fluid="xl">
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="simple-tags text-center">
									{keyWord &&
										keyWord.map((item, index) => {
											return <span key={index}>{item}</span>;
										})}
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<LetsCraftSection />
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default Development;
