import React from "react";

import { Row, Col } from "react-bootstrap";

const DEVELOPMENT = [
	{ text: "iOS app", link: "ios" },
	{ text: "Android", link: "android" },
	{ text: "Php", link: "php" },
	{ text: "Laravel", link: "laravel" },
	{ text: "Node Js", link: "nodejs" },
	{ text: "React Js", link: "reactjs" },
	{ text: "Angular Js", link: "angularjs" },
	{ text: "Codeigniter", link: "codeigniter" },
	{ text: "Wordpress", link: "wordpress" },
	{ text: "Magento", link: "magento" },
	{ text: "React Native", link: "reactnative" },
	{ text: "Flutter", link: "flutter" },
	{ text: "Java", link: "java" },
	{ text: ".net", link: "dot-net" },
];

const DEVELOPER = [
	{ text: "Hire iOS app", link: "ios" },
	{ text: "Hire Android", link: "android" },
	{ text: "Hire Php", link: "php" },
	{ text: "Hire Laravel", link: "laravel" },
	{ text: "Hire Node Js", link: "nodejs" },
	{ text: "Hire React Js", link: "reactjs" },
	{ text: "Hire Angular Js", link: "angularjs" },
	{ text: "Hire Codeigniter", link: "codeigniter" },
	{ text: "Hire Wordpress", link: "wordpress" },
	{ text: "Hire Magento", link: "magento" },
	{ text: "Hire React Native", link: "reactnative" },
	{ text: "Hire Flutter", link: "flutter" },
	{ text: "HireJava", link: "java" },
	{ text: "Hire .net", link: "dot-net" },
];

const FooterLinks = () => {
	return (
		<Row>
			<Col lg md sm xs={6}>
				<h6>PROJECTS</h6>
				<ul className="list-unstyled mb-4">
					<li>
						<a href="/product/fashion-store">Fashion Store</a>
					</li>
					<li>
						<a href="/project/life-is-wine">Life is Wine</a>
					</li>
					<li>
						<a href="/project/buy-sell">Buy & Sell</a>
					</li>
					<li>
						<a href="/product/grocery-store">Grocery Store</a>
					</li>
					<li>
						<a href="/project/happy-grocery">Happy Grocery</a>
					</li>
					<li>
						<a href="/project/happy-home">Happy Home</a>
					</li>
					<li>
						<a href="/project/chamber-link">Chamber Link</a>
					</li>
				</ul>
			</Col>
			<Col lg md sm xs={6}>
				<h6>HIRE RESOURCES</h6>
				<ul className="list-unstyled mb-4">
					{DEVELOPER &&
						DEVELOPER.map((item, index) => {
							return (
								<li key={index}>
									<a href={`/hire-${item.link}-developer`}>
										{item.text} Developer
									</a>
								</li>
							);
						})}
				</ul>
			</Col>
			<Col lg md sm xs={6}>
				<h6>DEVELOPMENT</h6>
				<ul className="list-unstyled mb-4">
					{DEVELOPMENT &&
						DEVELOPMENT.map((item, index) => {
							return (
								<li key={index}>
									<a href={`/${item.link}-development`}>
										{item.text} Development
									</a>
								</li>
							);
						})}
				</ul>
			</Col>
			<Col lg md sm xs={6}>
				<h6>DESIGN</h6>
				<ul className="list-unstyled mb-4">
					<li>
						<a href="">Wireframing</a>
					</li>
					<li>
						<a href="/service/user-experience-design">User experience design</a>
					</li>
					<li>
						<a href="/service/user-interface-design">User interface design</a>
					</li>
					<li>
						<a href="#">Prototyping & animation</a>
					</li>
					<li>
						<a href="/service/branding-design">Branding</a>
					</li>
					<li>
						<a href="#">Print & media</a>
					</li>
					<li>
						<a href="/service/mobile-application-design">Mobile app design</a>
					</li>
					<li>
						<a href="/service/website-design">Website design</a>
					</li>
					<li>
						<a href="#">illustrations</a>
					</li>
				</ul>
			</Col>
			<Col lg md sm xs={6}>
				<h6>COMPANY</h6>
				<ul className="list-unstyled mb-4">
					<li>
						<a href="/services">Services</a>
					</li>
					<li>
						<a href="/our-work">Work</a>
					</li>
					<li>
						<a href="/blogs">Blogs</a>
					</li>
					<li>
						<a href="/about">About</a>
					</li>
					<li>
						<a href="/contact">Contact</a>
					</li>
				</ul>
			</Col>
		</Row>
	);
};

export default FooterLinks;
