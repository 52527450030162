import React, { useState, useEffect } from "react";

import Select from "react-select";
import { toast } from "react-toastify";
import { Row, Col, Container, Card, Button, Form } from "react-bootstrap";

import { DEFAULT } from "../utils/keyWords";
import { Layout, Footer, LetsCraftSection } from "../components";
import {
	MOBILEAPP_SERVICES,
	WEBAPP_SERVICES,
	DESIGN_SERVICES,
	ldJson,
	HIRERESOURCES_URL,
	DOMAIN,
	INDUSTRY_SERVICES,
} from "../utils/constants";

import HIRE_TALENT_ICON from "../assets/images/hire-talent-icon.svg";
import MOBILE_BG from "../assets/images/mobile-bg@2x.png";
import WEB_BG from "../assets/images/web-bg@2x.png";
import DESIGN_BG from "../assets/images/design-bg@2x.png";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const RESOURCE_OPTIONS = [
	{ value: "iOS Developer", label: "iOS Developer" },
	{ value: "Android Developer", label: "Android Developer" },
	{ value: "Flutter Developer", label: "Flutter Developer" },
	{ value: "React Native Developer", label: "React Native Developer" },
	{ value: "Php Developer", label: "Php Developer" },
	{ value: "Laravel Developer", label: "Laravel Developer" },
	{ value: "Wordpress Developer", label: "Wordpress Developer" },
	{ value: "Node Js Developer", label: "Node Js Developer" },
	{ value: "React Js Developer", label: "React Js Developer" },
	{ value: "Angular Developer", label: "Angular Developer" },
	{ value: "Codeigniter Developer", label: "Codeigniter Developer" },
	{ value: "Javascript Developer", label: "Javascript Developer" },
	{ value: "Java Developer", label: "Java Developer" },
	{ value: "ASP.net Developer", label: "ASP.net Developer" },
	{ value: "Shopify Developer", label: "Shopify Developer" },
	{ value: "Front-end Developer", label: "Front-end Developer" },
	{ value: "Back-end Developer", label: "Back-end Developer" },
	{ value: "Others", label: "Others" },
];

const HIRING_OPTIONS = [
	{ value: "Full time", label: "Full time" },
	{ value: "Part time", label: "Part time" },
	{ value: "Hourly", label: "Hourly" },
];

const TIME_PERIOD_OPTIONS = [
	{ value: "1 to 3 months", label: "1 to 3 months" },
	{ value: "4 to 6 months", label: "4 to 6 months" },
	{ value: "6 to 12 months", label: "6 to 12 months" },
];

const Services = () => {
	ReactGA.pageview("ServiceDevelopmentPage");

	const [email, setEmail] = useState("");
	const [resource_type, setResourceType] = useState("");
	const [time_period, setTimePeriod] = useState("");
	const [hiring_module, setHiringModule] = useState("");
	const [requirement_description, setRequirementDescription] = useState("");
	const [error, setError] = useState({});
	const [isDisabled, setIsDisabled] = useState(false);

	const [oneHeight, setOneHeight] = useState();
	const [twoHeight, setTwoHeight] = useState();
	const [threeHeight, setThreeHeight] = useState();
	const [sixHeight, setSixHeight] = useState();

	const handleSubmit = (e) => {
		e.preventDefault();

		const error = {};
		const defaultError = "This fields is required.";

		if (!resource_type) {
			error["resource_type"] = defaultError;
			setError(error);
		}

		if (!email) {
			error["email"] = defaultError;
			setError(error);
		}

		if (!time_period) {
			error["time_period"] = defaultError;
			setError(error);
		}

		if (!hiring_module) {
			error["hiring_module"] = defaultError;
			setError(error);
		}

		if (!requirement_description) {
			error["requirement_description"] = defaultError;
			setError(error);
		}

		let isValidate = Object.keys(error).length;
		if (!isValidate) {
			setIsDisabled(true);
			const payload = {
				resource_type: resource_type.value,
				email,
				time_period: time_period.value,
				hiring_module: hiring_module.value,
				requirement_description,
			};
			fetch(HIRERESOURCES_URL, {
				method: "POST",
				body: JSON.stringify(payload),
				headers: {
					"Content-type": "application/json",
				},
			}).then(
				(response) => {
					if (response.status === 200) {
						toast.success("Thank you! We will contact you soon.");
					}
					setIsDisabled(false);
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};

	useEffect(() => {
		let soneHeight = document.getElementsByClassName("s-one");
		let soneArray = [];
		for (let a = 0; a < soneHeight.length; a++) {
			soneArray.push(soneHeight[a].offsetHeight);
		}
		setOneHeight((oneHeight) => Math.max(...soneArray));

		let stwoHeight = document.getElementsByClassName("s-two");
		let stwoArray = [];
		for (let a = 0; a < stwoHeight.length; a++) {
			stwoArray.push(stwoHeight[a].offsetHeight);
		}
		setTwoHeight((twoHeight) => Math.max(...stwoArray));

		let sthreeHeight = document.getElementsByClassName("s-three");
		let sthreeArray = [];
		for (let a = 0; a < sthreeHeight.length; a++) {
			sthreeArray.push(sthreeHeight[a].offsetHeight);
		}
		setThreeHeight((threeHeight) => Math.max(...sthreeArray));

		let sixHeight = document.getElementsByClassName("s-six");
		let ssixArray = [];
		for (let a = 0; a < sixHeight.length; a++) {
			ssixArray.push(sixHeight[a].offsetHeight);
		}
		setSixHeight((sixHeight) => Math.max(...ssixArray));
	}, []);

	const ServiceContainer = ({ serviceItem, children }) => {
		return (
			<div className={`section service service-${serviceItem}`}>
				<Container fluid="xl">
					<div className="wrapper">{children}</div>
				</Container>
			</div>
		);
	};

	const ServiceHeader = ({ header, content, path }) => {
		return (
			<>
				<h2>
					<a href={path}>{header}</a>
				</h2>
				<p className="title">{content}</p>
			</>
		);
	};

	const ServiceSection = ({ item, cardHeight, service }) => {
		return (
			<Col
				lg={service === "s-three" ? 6 : service === "s-six" ? 12 : 4}
				md={service === "s-three" ? 6 : service === "s-six" ? 12 : 4}
				sm={6}
				xs={12}
				className="mb-3"
			>
				<Card>
					<Card.Body
						className={`text-center ${service}`}
						style={{ height: `${cardHeight}px` }}
					>
						<ul className="list-inline mb-2">
							<li className="list-inline-item">
								<Card.Img
									src={item.icon}
									alt={item.title}
									width="36"
									height="36"
								/>
							</li>
							<li className="list-inline-item" style={{ verticalAlign: "top" }}>
								<Card.Title className="mt-1">{item.title}</Card.Title>
							</li>
						</ul>
						<ul className="list-inline mb-0 tags">
							{item.tags &&
								item.tags.map((item, index) => {
									return (
										<li className="list-inline-item" key={index}>
											<a href={item.href}>{item.text}</a>
										</li>
									);
								})}
						</ul>
					</Card.Body>
				</Card>
			</Col>
		);
	};

	const ExploreWork = ({ bgImg }) => {
		return (
			<Row className="mt-lg-5">
				<Col lg={12} md={12} sm={12} xs={12}>
					<div
						className="explore-section"
						style={{
							textAlign: "center",
							background: `url(${bgImg}) no-repeat`,
							backgroundPosition: "center center",
							backgroundSize: "cover",
						}}
					>
						<Button
							href="/our-work"
							size="lg"
							variant="light"
							className="explore"
							style={{ margin: "17px 0px", border: "0px" }}
						>
							Explore Work
						</Button>
					</div>
				</Col>
			</Row>
		);
	};

	const handleResource = (e) => {
		setResourceType(e);
	};

	const handleHiringModule = (e) => {
		setHiringModule(e);
	};

	const handleTimePeriod = (e) => {
		setTimePeriod(e);
	};

	const handleEmail = (e) => {
		setEmail(e.target.value);
	};

	const handleDescription = (e) => {
		setRequirementDescription(e.target.value);
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>
					Custom Mobile and Website Development Company and Services India and
					USA | Nxinfoway
				</title>
				<meta
					name="description"
					content="Nxinfoway is one of the best Mobile and Web service providers in India and USA. We use the latest technologies like Kotlin, Swift, Java, HTML5, CSS3, J-Query, Bootraps, AngularJs, NodeJs, AngularJS etc. To make your website and mobile app perfect and easy to use."
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="services, app and web development services"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="Custom Mobile and Website Development Company and Services India and USA | Nxinfoway"
					data-react-helmet="true"
					//Development Company India, USA | iOS App Development Services | Nxinfoway
				/>
				<meta
					property="og:description"
					content="Nxinfoway is one of the best Mobile and Web service providers in India and USA. We use the latest technologies like Kotlin, Swift, Java, HTML5, CSS3, J-Query, Bootraps, AngularJs, NodeJs, AngularJS etc. To make your website and mobile app perfect and easy to use."
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content="Custom Mobile and Website Development Company and Services India and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Nxinfoway is one of the best Mobile and Web service providers in India and USA. We use the latest technologies like Kotlin, Swift, Java, HTML5, CSS3, J-Query, Bootraps, AngularJs, NodeJs, AngularJS etc. To make your website and mobile app perfect and easy to use."
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />

				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
			</Helmet>
			<Layout>
				<div className="section service head">
					<Container fluid="xl">
						<div className="wrapper">
							<h1>Our Services</h1>
							<p className="title">
								Focused on crafting beautiful digital products for everyday use.
							</p>
						</div>
					</Container>
				</div>
				<ServiceContainer serviceItem="one">
					<ServiceHeader
						header="Mobile App Development"
						content="Our software engineers uses both native and cross platform technologies to build digital products."
						path="/service/mobile-app-development"
					/>
					<Row className="mt-lg-4 pt-3">
						{MOBILEAPP_SERVICES &&
							MOBILEAPP_SERVICES.map((item, index) => (
								<ServiceSection
									item={item}
									cardHeight={oneHeight}
									service="one"
									key={index}
								/>
							))}
					</Row>
					<ExploreWork bgImg={MOBILE_BG} />
				</ServiceContainer>

				<ServiceContainer serviceItem="two">
					<ServiceHeader
						header="Web Development"
						content="Enterprise solutions, eCommerce store, front-end, back-end, web apps, CRM, ERP, blockchain, iOT, AI & ML."
						path="/service/web-development"
					/>
					<Row className="mt-lg-4 pt-3">
						{WEBAPP_SERVICES &&
							WEBAPP_SERVICES.map((item, index) => (
								<ServiceSection
									item={item}
									cardHeight={twoHeight}
									service="s-two"
									key={index}
								/>
							))}
					</Row>
					<ExploreWork bgImg={WEB_BG} />
				</ServiceContainer>

				<ServiceContainer serviceItem="three">
					<ServiceHeader
						header="UI UX Design"
						content="Ideas turns into interaction, user experience, user interface, visual identity, a polished product."
						path="/service/design-services"
					/>
					<Row className="mt-lg-4 pt-3">
						{DESIGN_SERVICES &&
							DESIGN_SERVICES.map((item, index) => (
								<ServiceSection
									item={item}
									cardHeight={threeHeight}
									service="s-three"
									key={index}
								/>
							))}
					</Row>
					<ExploreWork bgImg={DESIGN_BG} />
				</ServiceContainer>

				<ServiceContainer serviceItem="six">
					<ServiceHeader
						header="Industry Wise Solutions"
						content="Industries we serve and continuously exploring to take part in building delightful experiences for next gen."
						path="/service/industry"
					/>
					<Row className="mt-lg-4 pt-3">
						{INDUSTRY_SERVICES &&
							INDUSTRY_SERVICES.map((item, index) => (
								<ServiceSection
									item={item}
									cardHeight={sixHeight}
									service="s-six"
									key={index}
								/>
							))}
					</Row>
					<ExploreWork bgImg={DESIGN_BG} />
				</ServiceContainer>

				<div className="section service service-four">
					<Container fluid="xl">
						<div className="wrapper">
							<ul className="list-inline mb-0">
								<li className="list-inline-item">
									<img
										src={HIRE_TALENT_ICON}
										alt="hire talent"
										width="36"
										height="36"
										style={{ verticalAlign: "bottom" }}
									/>
								</li>
								<li className="list-inline-item">
									<h2>Hire Resources</h2>
								</li>
							</ul>
							<p className="title">
								Well experienced technical force of developers, designers, IT
								professionals are always ready to shape your next big thing as
								your own dedicated recourse for your team, or your company.
							</p>
							<form className="hire-resource-form" onSubmit={handleSubmit}>
								<Row className="mt-lg-5 pt-3">
									<Col lg={6} md={6} sm={6} xs={12}>
										<div className="mb-3">
											<label>Resource type</label>
											<Select
												options={RESOURCE_OPTIONS}
												name="resource_type"
												onChange={handleResource}
												value={resource_type}
											/>
											<p className="text-danger">
												{error.resource_type ? error.resource_type : ""}
											</p>
										</div>
										<div className="mb-3">
											<label>Hiring Module</label>
											<Select
												options={HIRING_OPTIONS}
												name="hiring_module"
												onChange={handleHiringModule}
												value={hiring_module}
											/>
											<p className="text-danger">
												{error.hiring_module ? error.hiring_module : ""}
											</p>
										</div>
									</Col>
									<Col lg={6} md={6} sm={6} xs={12}>
										<div className="mb-3">
											<label>Time Period</label>
											<Select
												options={TIME_PERIOD_OPTIONS}
												name="time_period"
												onChange={handleTimePeriod}
												value={time_period}
											/>
											<p className="text-danger">
												{error.time_period ? error.time_period : ""}
											</p>
										</div>
										<div className="mb-3">
											<label>Email</label>
											<input
												type="text"
												className="form-control"
												placeholder="Example@gmail.com"
												name="email"
												onChange={handleEmail}
												value={email}
											/>
											<p className="text-danger">
												{error.email ? error.email : ""}
											</p>
										</div>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<div className="mb-3">
											<textarea
												rows="5"
												className="form-control"
												placeholder="Describe your requirement"
												name="describe"
												onChange={handleDescription}
												value={requirement_description}
											/>
											<p className="text-danger">
												{error.requirement_description
													? error.requirement_description
													: ""}
											</p>
										</div>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12} className="text-end">
										<Button
											size="lg"
											varient="primary"
											type="submit"
											disabled={isDisabled}
										>
											Send Inquiry
										</Button>
									</Col>
								</Row>
							</form>
						</div>
					</Container>
				</div>

				<div className="section service about">
					<Container fluid="xl">
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="simple-tags text-center mt-4 pb-5">
									{DEFAULT &&
										DEFAULT.map((item, index) => {
											return <span key={index}>{item}</span>;
										})}
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<LetsCraftSection />
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default Services;
