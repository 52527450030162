import { Col } from 'react-bootstrap';

const WorkProfile = ({pside, children}) => {
    return (
        <Col lg={12} md={12} sm={12} xs={12}>
            <div className={`workprofile ${pside}`}>
                {children}
            </div>
        </Col>
    )
}

export default WorkProfile
