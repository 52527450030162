import React from "react";
import { Image } from "react-bootstrap";

import TWITTER from "../assets/images/social-icons/twitter@2x.png";
import SKYPE from "../assets/images/social-icons/skype-icon-black@2x.png";
import LINKDIN from "../assets/images/social-icons/linkedIn-icon-black@2x.png";
import FACEBOOK from "../assets/images/social-icons/facebook-icon-black@2x.png";
import INSTAGRAM from "../assets/images/social-icons/Instagram-icon-black@2x.png";

import {
	INSTA_URL,
	SKYPE_URL,
	LINKEDIN_URL,
	FACEBOOK_URL,
	TWITTER_URL,
} from "../utils/constants";

const MenuList = [
	{
		name: "skype",
		url: SKYPE_URL,
		image: SKYPE,
	},
	{
		name: "linkdin",
		url: LINKEDIN_URL,
		image: LINKDIN,
	},
	{
		name: "facebook",
		url: FACEBOOK_URL,
		image: FACEBOOK,
	},
	{
		name: "twitter",
		url: TWITTER_URL,
		image: TWITTER,
	},
	{
		name: "instagram",
		url: INSTA_URL,
		image: INSTAGRAM,
	},
];

const SocialIconMainMenu = () => {
	return (
		<React.Fragment>
			{MenuList.map((item, index) => {
				return (
					<li
						className={`list-inline-item ${
							MenuList.length - 1 === index ? "" : "me-4"
						}`}
						key={index}
					>
						<a href={item.url} target="_blank" rel="noreferrer">
							<Image src={item.image} alt={item.name} />
						</a>
					</li>
				);
			})}
		</React.Fragment>
	);
};

export default SocialIconMainMenu;
