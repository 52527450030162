import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Image, Container, Card } from "react-bootstrap";
import ReactFullpage from "@fullpage/react-fullpage";
import { FiArrowDown } from "react-icons/fi";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";
import Typed from "react-typed";
import { Helmet } from "react-helmet";
import {
	Layout,
	Footer,
	ProductMainSection,
	SmLink,
	HowWeDo,
} from "../components";
import {
	HOME_TEXT,
	HOME_PAGE_SLIDES,
	WHAT_WE_DO_CARDS,
	TECHNOLOGIES_ONE,
	DOMAIN,
	ldJson,
} from "../utils/constants";
import ReactGA from "react-ga";

const leftSettings = {
	dots: false,
	infinite: true,
	autoplay: true,
	slidesToShow: 8,
	slidesToScroll: 1,
	speed: 4000,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	variableWidth: true,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const rightSettings = {
	dots: false,
	infinite: true,
	autoplay: true,
	slidesToShow: 8,
	slidesToScroll: 1,
	speed: 4000,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	variableWidth: true,
	cssEase: "linear",
	rtl: true,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const Home = () => {
	const whatWeDo = useRef(null);
	const howWeDo = useRef(null);
	const technologyExp = useRef(null);
	const testimonial = useRef(null);
	const contactInfo = useRef(null);
	const projectRef = useRef(null);

	const [currentHead, setCurrentHeading] = useState(HOME_TEXT[0].heading);
	const [typeText, setTypeText] = useState(HOME_TEXT[0].scrollHeading);
	const [currentImage, setCurrentImage] = useState(HOME_TEXT[0].image);
	const [currentText, setCurrentText] = useState(1);
	const [currentSlide, setCurrentSlide] = useState({
		index: 0,
		direction: "down",
	});

	const textItem = () => {
		if (currentText >= HOME_TEXT.length - 1) {
			setCurrentText(0);
		} else {
			setCurrentText(currentText + 1);
		}
		setCurrentImage(HOME_TEXT[currentText].image);
		setCurrentHeading(HOME_TEXT[currentText].heading);
		setTypeText(HOME_TEXT[currentText].scrollHeading);
	};

	const executeScroll = (refId) => refId.current.scrollIntoView();

	// const scrollTOSection = (refId) => {
	//     // window.scrollTo({
	//     //     behavior: "smooth",
	//     //     top: refId.current.offsetTop
	//     //   });
	//     // refId.current.scrollIntoView();
	// }

	const getActiveSection = (origin, destination, direction) => {
		setCurrentSlide({ index: origin.index, direction });
	};

	ReactGA.pageview("HomePage");

	return (
		<React.Fragment>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>

				<title>
				   Mobile and Web Development Company in india and USA | Nxinfoway
				</title>
				<meta
					name="description"
					content="Nxinfoway leading mobile, web design &amp; website development company in india and USA, and all of California. We delivers custom website design, UI &amp; UX, responsive design, Android, iPhone, ReactNative, Flutter development, SEO optimized websites. Choice of CMS-WordPress, NodeJS, frameworks-React, Angular, Vue and built on ASP.Net &amp; PHP platforms."
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="mobile application development company India, PHP web development company, iPhone apps development, mobile app development, android app development"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="Mobile and Web Development Company in india and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Nxinfoway leading mobile, web design &amp; website development company in India and USA. We delivers custom website design, UI &amp; UX, responsive design, Android, iPhone, ReactNative, Flutter development, SEO optimized websites. Choice of CMS-WordPress, NodeJS, frameworks-React, Angular, Vue and built on ASP.Net &amp; PHP platforms."
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content="Mobile and Web Development Company in india and USA | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Nxinfoway leading mobile, web design &amp; website development company in India and USA. We delivers custom website design, UI &amp; UX, responsive design, Android, iPhone, ReactNative, Flutter development, SEO optimized websites. Choice of CMS-WordPress, NodeJS, frameworks-React, Angular, Vue and built on ASP.Net &amp; PHP platforms."
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />
			</Helmet>

			<Layout homepage={true} currentSlide={currentSlide}>
				<ReactFullpage
					licenseKey={"YOUR_KEY_HERE"}
					scrollingSpeed={1000}
					scrollBar={false}
					responsiveWidth={361}
					responsiveHeight={640}
					afterLoad={(origin, destination, direction) =>
						getActiveSection(origin, destination, direction)
					}
					render={({ state, fullpageApi }) => {
						return (
							<ReactFullpage.Wrapper>
								<div className="section blackbg">
									<Container fluid="xl">
										<div className="wrapper home-jumotron">
											<div className="d-block d-sm-none main-img-section mb-3">
												<Image
													src={currentImage}
													fluid="true"
													style={{ padding: "25px" }}
												/>
											</div>
											<div className="d-flex justify-content-between">
												<div className="align-self-center">
													<div className="main-sectionHead">{currentHead}</div>
													<div className="main-sectionHead">
														<Typed
															strings={typeText}
															typeSpeed={100}
															backSpeed={25}
															loop
															onLastStringBackspaced={textItem}
														/>
													</div>
												</div>
												<div className="d-none d-sm-block">
													<Fade left duration={1000}>
														<Image
															src={currentImage}
															fluid="true"
															style={{ height: "300px" }}
														/>
													</Fade>
												</div>
											</div>
										</div>
									</Container>
									<div className="bottom-section">
										<Container fluid="xl">
											<Row>
												<Col lg={12} md={12} sm={12} xs={12}>
													<ul className="d-flex justify-content-around list-unstyled m-0">
														<li className="text-center d-none d-sm-block">
															<Link
																to="#"
																onClick={() => executeScroll(projectRef)}
															>
																Featured
																<br />
																Projects
																<br />
																<span>
																	<FiArrowDown />
																</span>
																<br />
															</Link>
														</li>
														<li className="align-self-center">
															<Link
																to="#"
																onClick={() => fullpageApi.moveTo(9, 0)}
															>
																What we do?
															</Link>
														</li>
														<li className="align-self-center d-none d-sm-block">
															<Link
																to="#"
																onClick={() => fullpageApi.moveTo(10, 0)}
															>
																How we do?
															</Link>
														</li>
														<li className="align-self-center">
															<a href="/our-work">Explore work</a>
														</li>
													</ul>
												</Col>
											</Row>
											<Row className="d-block d-sm-none">
												<Col lg={12} md={12} sm={12} xs={12}>
													<ul className="d-flex justify-content-around list-unstyled mt-lg-5 mt-3">
														<li className="text-center">
															<Link
																to="#"
																onClick={() => executeScroll(projectRef)}
															>
																<span>
																	<FiArrowDown />
																</span>
																<br />
															</Link>
														</li>
													</ul>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
								<div className="section" ref={projectRef}>
									<Container fluid="xl" className="product-section">
										<Fade bottom duration={300}>
											<Row>
												<Col
													lg={6}
													md={6}
													sm={6}
													xs={12}
													style={{ paddingTop: "129px" }}
												>
													<ProductMainSection
														smTitle={HOME_PAGE_SLIDES[0].smTitle}
														mainHeader={HOME_PAGE_SLIDES[0].mainHeader}
														pContent={HOME_PAGE_SLIDES[0].pContent}
														buttonLabel={HOME_PAGE_SLIDES[0].buttonLabel}
														headerColor={HOME_PAGE_SLIDES[0].headerColor}
													/>
												</Col>
												<Col lg={6} md={6} sm={6} xs={12} className="pb-5">
													<Image
														src={HOME_PAGE_SLIDES[0].mainImage}
														alt={HOME_PAGE_SLIDES[0].mainHeader}
														fluid="true"
													/>
												</Col>
											</Row>
										</Fade>
									</Container>
								</div>
								{HOME_PAGE_SLIDES &&
									HOME_PAGE_SLIDES.map((item, index) => {
										return index > 0 ? (
											<div className="section" key={index}>
												<Container fluid="xl" className="product-section">
													<Fade bottom duration={300}>
														<Row>
															<Col
																lg={6}
																md={6}
																sm={6}
																xs={12}
																className="left-content"
															>
																<ProductMainSection
																	smTitle={item.smTitle}
																	mainHeader={item.mainHeader}
																	pContent={item.pContent}
																	buttonLabel={item.buttonLabel}
																	headerColor={item.headerColor}
																/>
															</Col>
															<Col
																lg={6}
																md={6}
																sm={6}
																xs={12}
																className="pb-5"
															>
																<Image
																	src={item.mainImage}
																	alt={item.mainHeader}
																	fluid="true"
																/>
															</Col>
														</Row>
													</Fade>
												</Container>
											</div>
										) : (
											""
										);
									})}
								<div className="section darkbg" ref={whatWeDo}>
									<Container fluid="xl" className="section-wrapper">
										<div className="sectionHead">What we do?</div>
										<Row
											className="mt-4 whtwedo"
											style={{ marginRight: `-${window.innerWidth - 1088}px` }}
										>
											{WHAT_WE_DO_CARDS &&
												WHAT_WE_DO_CARDS.map((item, index) => {
													return (
														<React.Fragment key={index}>
															<Col className="mb-3 d-block d-sm-none">
																<Fade right duration={500}>
																	<Card border={item.borderColor}>
																		<Card.Body>
																			{item.header === "eCommerce Solutions" ? (
																				<div className="d-flex align-items-center">
																					<div className="">
																						<Card.Img
																							variant="top"
																							src={item.image}
																							fluid="true"
																							style={{ width: "135px" }}
																						/>
																					</div>
																					<div className="flex-grow-1 ms-3">
																						<Card.Title
																							className={item.headerColor}
																							style={{
																								padding: "20px 0px 0px 0px",
																								textAlign: "left",
																							}}
																						>
																							{item.header}
																						</Card.Title>
																					</div>
																				</div>
																			) : (
																				<React.Fragment>
																					<Card.Img
																						variant="top"
																						src={item.image}
																						fluid="true"
																					/>
																					{item.header === "Web Development" ? (
																						<Card.Title
																							className={item.headerColor}
																							style={{ fontSize: "14px" }}
																						>
																							{item.header}
																						</Card.Title>
																					) : (
																						<Card.Title
																							className={item.headerColor}
																						>
																							{item.header}
																						</Card.Title>
																					)}
																				</React.Fragment>
																			)}
																		</Card.Body>
																	</Card>
																</Fade>
															</Col>
															<Col
																lg={4}
																md={4}
																sm={6}
																xs={6}
																style={{
																	marginRight: `${
																		WHAT_WE_DO_CARDS.length === index + 1
																			? window.innerWidth - 1088
																			: 0
																	}px`,
																}}
																className="mb-3 d-none d-sm-block"
															>
																<Fade right duration={500}>
																	<Card border={item.borderColor}>
																		<Card.Body>
																			<Card.Img
																				variant="top"
																				src={item.image}
																				fluid="true"
																			/>
																			{item.header === "eCommerce Solutions" ? (
																				<Card.Title
																					className={item.headerColor}
																					style={{
																						padding: "20px 0px 0px 0px",
																					}}
																				>
																					{item.header}
																				</Card.Title>
																			) : (
																				<Card.Title
																					className={item.headerColor}
																				>
																					{item.header}
																				</Card.Title>
																			)}
																		</Card.Body>
																	</Card>
																</Fade>
															</Col>
														</React.Fragment>
													);
												})}
										</Row>
										<Row className="pt-5">
											<Col lg={6} md={6} sm={6} xs={6} className="text-start">
												<SmLink
													url="#"
													textAlign="text-start"
													label="How we do ?"
													onClick={() => executeScroll(howWeDo)}
												/>
											</Col>
											<Col lg={6} md={6} sm={6} xs={6} className="text-end">
												<SmLink
													url="/services"
													textAlign="text-end"
													label="Explore services"
												/>
											</Col>
										</Row>
									</Container>
								</div>
								<div className="section darkbg" ref={howWeDo}>
									<Container fluid="xl" className="section-wrapper">
										<div className="sectionHead">How we do?</div>
										<HowWeDo />
										<Row className="pt-5">
											<Col lg={6} md={6} sm={6} xs={12} className="text-start">
												<SmLink
													url="#"
													onClick={() => executeScroll(technologyExp)}
													textAlign="text-start"
													label="Our Technology Experties"
												/>
											</Col>
										</Row>
									</Container>
								</div>
								<div className="section darkbg" ref={technologyExp}>
									<div style={{ padding: "88px 0px 40px 0px" }}>
										<Container fluid="xl">
											<div className="sectionHead">Technology expertise</div>
										</Container>
										<div className="mt-4">
											<Slider {...leftSettings}>
												{TECHNOLOGIES_ONE &&
													TECHNOLOGIES_ONE.map((item, index) => {
														return (
															index % 2 === 0 && (
																<div
																	className="technologies mr-3 mb-3"
																	key={index}
																>
																	<img
																		src={item.icon}
																		alt={item.name}
																		width="44"
																		height="44"
																	/>
																	<div>{item.name}</div>
																</div>
															)
														);
													})}
											</Slider>
											<Slider {...rightSettings}>
												{TECHNOLOGIES_ONE &&
													TECHNOLOGIES_ONE.map((item, index) => {
														return (
															index % 2 !== 0 && (
																<div
																	className="technologies mx-2 mb-3"
																	key={index}
																>
																	<img
																		src={item.icon}
																		alt={item.name}
																		width="44"
																		height="44"
																	/>
																	<div>{item.name}</div>
																</div>
															)
														);
													})}
											</Slider>
										</div>
										<Container fluid="xl">
											<Row className="pt-5">
												<Col
													lg={6}
													md={6}
													sm={6}
													xs={12}
													className="text-start"
												>
													<SmLink
														url="#"
														onClick={() => executeScroll(testimonial)}
														textAlign="text-start"
														label="What our clients say ?"
													/>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
								<Footer refId={testimonial} />
							</ReactFullpage.Wrapper>
						);
					}}
				/>
			</Layout>
		</React.Fragment>
	);
};

export default Home;
