import React, { useRef } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import Slider from "react-slick";

import { COMPANYLOGO, ldJson, DOMAIN } from "../utils/constants";
import { ABOUT_PAGE } from "../utils/keyWords";
import {
	Layout,
	SmLink,
	Testimonial,
	Located,
	Footer,
	NumberSection,
	LetsCraftSection,
} from "../components";

import WHY_US from "../assets/images/about/why-us@2x.png";
import WHO_WE_ARE from "../assets/images/about/who-we-are@2x.png";
import WHAT_WE_DO from "../assets/images/about/what-we-do@2x.png";
import IMAGE1 from "../assets/images/about/image1@2x.png";
import IMAGE2 from "../assets/images/about/image2@2x.png";

import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const leftSettings = {
	dots: false,
	infinite: true,
	autoplay: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	speed: 4000,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	variableWidth: true,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const About = (props) => {
	const { refId } = props;
	const contactInfo = useRef();

	const scrollTOSection = (refId) => {
		refId.current.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		});
	};

	ReactGA.pageview("AboutUSPage");

	return (
		<React.Fragment>
			<Helmet>
				<title>About Us | Nxinfoway</title>
				<meta
					name="description"
					content="Know more about Nxinfoway Our Vision, Our Mission,Our Team, why us, our infrastructure, our development methodology etc"
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="company overview, Nxinfoway overview, Nxinfoway company overview, why us, our infrastructure, our development methodology"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="About Us | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Know more about Nxinfoway Our Vision, Our Mission,Our Team, why us, our infrastructure, our development methodology etc"
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content="About Us | Nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Know more about Nxinfoway Our Vision, Our Mission,Our Team, why us, our infrastructure, our development methodology etc"
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Digital Marketing Agency,Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />

				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
			</Helmet>
			<Layout>
				<div className="section service head about" ref={refId}>
					<Container fluid="xl">
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="wrapper pe-lg-5 pb-lg-5">
									<h1>About us</h1>
									<div className="about-content">
										<Row>
											<Col lg={3} md={3} sm={3} xs={12}>
												<div className="img-section">
													<Image src={WHO_WE_ARE} alt="who-we-are" fluid />
													<Row>
														<Col
															lg={{ span: 8, offset: 4 }}
															md={{ span: 8, offset: 4 }}
															sm={{ span: 8, offset: 4 }}
															xs={12}
														>
															<h3 className="m-0">Who we are?</h3>
														</Col>
													</Row>
												</div>
											</Col>
											<Col lg={8} md={8} sm={8} xs={12}>
												<p>
													A group of IT professionals working together on a
													mission to craft challenging ideas in to digital
													products which delivers clever & simplified human
													experience.
												</p>
											</Col>
										</Row>
									</div>
									<div className="about-content">
										<Row>
											<Col lg={3} md={3} sm={3} xs={12}>
												<div className="img-section">
													<Image src={WHY_US} alt="why-us" fluid />
													<Row>
														<Col
															lg={{ span: 8, offset: 4 }}
															md={{ span: 8, offset: 4 }}
															sm={{ span: 8, offset: 4 }}
															xs={12}
														>
															<h3 className="m-0">Why us?</h3>
														</Col>
													</Row>
												</div>
											</Col>
											<Col lg={8} md={8} sm={8} xs={12}>
												<p>
													Our technological capabilities in building creative
													solutions helps to bridges gap between our clients &
													their customers. So basically we provide solutions
													which helps our clients to reach their business goals.
													that’s matter most.
												</p>
											</Col>
										</Row>
									</div>
									<div className="about-content">
										<Row>
											<Col lg={3} md={3} sm={3} xs={12}>
												<div className="img-section">
													<Image src={WHAT_WE_DO} alt="what-we-do" fluid />
													<Row>
														<Col
															lg={{ span: 8, offset: 4 }}
															md={{ span: 8, offset: 4 }}
															sm={{ span: 8, offset: 4 }}
															xs={12}
														>
															<h3 className="m-0">What we do?</h3>
														</Col>
													</Row>
												</div>
											</Col>
											<Col lg={8} md={8} sm={8} xs={12}>
												<p className="mb-3">
													Learn about our core expertises and some of our works.
												</p>
												<ul className="list-inline m-0">
													<li className="list-inline-item me-3">
														<a href="/services">Services</a>
													</li>
													<li className="list-inline-item">
														<a href="/our-work">Work</a>
													</li>
												</ul>
											</Col>
										</Row>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="section-img">
							<Col lg={8} md={8} sm={8} xs={12}>
								<Image src={IMAGE1} alt="first-image" fluid />
							</Col>
							<Col lg={4} md={4} sm={4} xs={12}>
								<Image src={IMAGE2} alt="second-image" fluid />
							</Col>
						</Row>
					</Container>
					<div className="statistics">
						<Container fluid="xl">
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="content">
									<Row>
										<Col
											lg={{ span: 8, offset: 2 }}
											md={{ span: 8, offset: 2 }}
											sm={{ span: 8, offset: 2 }}
											xs={12}
										>
											<NumberSection />
										</Col>
									</Row>
								</div>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="company-logos">
									<Slider {...leftSettings}>
										{COMPANYLOGO &&
											COMPANYLOGO.map((item, index) => {
												return (
													<div className="" key={index}>
														<img
															src={item}
															alt={item}
															width="260"
															height="120"
														/>
													</div>
												);
											})}
									</Slider>
								</div>
							</Col>
						</Container>
					</div>
				</div>
				<div className="section service about">
					<div className="locations">
						<Container fluid="xl">
							<Located />
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="simple-tags text-center mt-4">
										{ABOUT_PAGE &&
											ABOUT_PAGE.map((item, index) => {
												return <span key={index}>{item}</span>;
											})}
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				<LetsCraftSection />
				<Footer />
			</Layout>
		</React.Fragment>
	);
};

export default About;
