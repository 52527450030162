import React, { useState, useEffect } from "react";

import { Header, MainMenu } from "../components";
import ScrollContext from "../utils/scrollContext";
import { ToastContainer } from "react-toastify";

const Layout = (props) => {
	const [navClassList, setNavClassList] = useState([]);
	const scroll = ScrollContext();

	const [click, setClick] = useState("0%");
	const handleClick = () => setClick("100%");
	const closeClick = () => setClick("0%");

	useEffect(() => {
		const _classList = [];
		if (scroll.y > 150 && scroll.y - scroll.lastY > 0) {
			_classList.push("nav-bar--hidden");
		}
		setNavClassList(_classList);
	}, [scroll.y, scroll.lastY]);

	return (
		<React.Fragment>
			<ToastContainer />
			<MainMenu closeClick={closeClick} click={click} />
			<Header
				homepage={props.homepage}
				handleClick={handleClick}
				navClass={navClassList.join(" ")}
				currentSlide={props.currentSlide}
			/>
			{props.children}
		</React.Fragment>
	);
};

export default Layout;
