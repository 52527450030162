import FashionStoreLogo from '../assets/images/products/fashion-store/fashion-store@2x.png';
import FashionStoreBanner from '../assets/images/products/fashion-store/fashion-store-banner@2x.png';
import FashionStoreImage1 from '../assets/images/products/fashion-store/image-1@2x.png';
import FashionStoreImage2 from '../assets/images/products/fashion-store/image-2@2x.png';
import FashionStoreImage3 from '../assets/images/products/fashion-store/image-3@2x.png';
import FashionStoreImage4 from '../assets/images/products/fashion-store/image-4@2x.png';

import GroceryStoreLogo from '../assets/images/products/grocery-store/grocery-store.png';
import GroceryStoreBanner from '../assets/images/products/grocery-store/grocery_store_a.jpg';
import GroceryStoreImage1 from '../assets/images/products/grocery-store/grocery_store_b.jpg';
import GroceryStoreImage2 from '../assets/images/products/grocery-store/grocery_store_c.jpg';
import GroceryStoreImage3 from '../assets/images/products/grocery-store/grocery_store_d.jpg';
import GroceryStoreImage4 from '../assets/images/products/grocery-store/grocery_store_e.jpg';

export const allProducts = [
    {
        "id": 1,
        "slug": "fashion-store",
        "name": "Fashion Store",
        "gaview": "FashionStorePage", 
        "logo": FashionStoreLogo,
        "banner": FashionStoreBanner,
        "mainParagraph": "A complete eCommerce multi vendor solution with beautiful and modern design, Being native to both mobile platforms it builds to delivers fast and smooth performance.",
        "technologies": ["iOS App", "Android App", "Admin & Super Admin panel"],
        "heading1": "Make your shopping store's experience beautiful and engaging with Fashion store's complete solution.",
        "content1": ["Created to provide an awesome and wonderful e-commerce experience to your delightful customers in everyday use.", "We aimed to make this product to provide faster and smoother performance as possible in the an ecommerce app segment , So we simply followed all the needful standards while building this product."],
        "badges": [
            {
                "header": "What's included",
                "items": [
                    "Android App Source Code", "iOS App Source Code", "PHP Script Source Code", "Admin Panel", "Super Admin"
                ]
            },
            {
                "header": "Technology used",
                "items": [
                    "iOS", "Android", "Laravel", "PHP", "MySQL", "HTML5", "CSS3", "Javascript"
                ]
            }
        ],
        "featureParagraph": "List of features for both iOS and Andoird App. Admin panel features to manage app listing, products, statistics etc..",
        "features": [
            {
                "header": "iOS, Android Mobile App",
                "items": ["Easy to Signup & Sign in", "Sign in with Mobile number/Email", "Profile Update", "Product Details", "Cart", "Checkout", "Notifications", "User Profile", "User Orders", "Feedback", "Share App", "Contact", "About", "Privacy Policy", "Address", "Notification & Notification Details", "Forget Password with Mobile OTP verification", "User can choice multiple payment options Cash on delivery", "Pickup Myself", "Pay with Online(Razorpay)", "User can choice date and time slot", "User can check the order details status Complete", "Processing", "Pending and history of orders", "User can Rate a Product Delivery Review", "Easy and Understand Design", "Unique and Beautiful UI"]
            },
            {
                "header": "Admin Panel Features",
                "items": ["Admin can see the Total Product, Total Area, Total Time-slot, Total Banner, Total Customer, Pending Order, Complete Order, Cancelled Order, Customer Rating, Total Feedback, Total Sales", "Admin can create unlimited Categories & Sub Categories", "Admin can create unlimited Banner", "Admin can create unlimited Time-slot", "Admin can create unlimited Area with Delivery Charges", "Admin can add/upload/delete Products", "Admin can select popular product", "Admin can Send Notification", "Admin can Create unlimited notification template and add/upload/delete template", "Admin can Send Notification once product added", "Admin can check customer product rating with Very Good, Good, Not Good", "Admin can check feedback with rating", "Admin can change currency($,₹,€,£,¥ etc)", "Admin can change User/Customer status anytime with active or deactive", "Setting for OneSignal API and OTP verification API", "Admin can check and update the order status - Complate, Processing, Pending", "Fast, Optimized and Powerful admin", "Beautiful UI", "High Quality", "Top quality clean code", "Easy configuration"]
            }
        ],
        "visualInsights": [
            {
                "header": "#image1",
                "imgSrc": FashionStoreImage1
            },
            {
                "header": "#image2",
                "imgSrc": FashionStoreImage2
            },
            {
                "header": "#image3",
                "imgSrc": FashionStoreImage3
            },
            {
                "header": "#image4",
                "imgSrc": FashionStoreImage4
            }
        ],
        "links": ["#", "#", "#"]
    },
    {
        "id": 2,
        "slug": "grocery-store",
        "name": "GroceryCart",
        "gaview": "GroceryStorePage",
        "logo": GroceryStoreLogo,
        "banner": GroceryStoreBanner,
        "mainParagraph": "A complete grocery multi language with delivery partner solution with beautiful and modern design, Being native to both mobile platforms it builds to delivers fast and smooth performance.",
        "technologies": ["iOS App", "Android App", "Admin panel","website"],
        "heading1": "Grocery store app is created as a wonderful solution for any agricultural or organic food shop Android / iOS / Website with delivery application",
        "content1": ["Create grocery application with a clean design, Multi language support with complete solution for customer and Delivery boy Android / iOS / Website with admina panel."],
        "badges": [
            {
                "header": "What's included",
                "items": [
                    "Android App Source Code", "iOS App Source Code", "CodeIgniter & PHP Script Source Code", "Admin Panel"
                ]
            },
            {
                "header": "Technology used",
                "items": [
                    "iOS-Swift", "Android-kotlin and java", "CodeIgniter", "PHP", "MySQL", "HTML5", "CSS3", "Javascript","Bootstrap"
                ]
            }
        ],
        "featureParagraph": "List of features for both iOS and Andoird customer and delivery application. Admin panel features to manage app listing, products, statistics, Orders etc..",
        "features": [
            {
                "header": "iOS, Android Mobile App for customer",
                "items": ["Easy to Signup & Sign in", "Sign in with Mobile number/Email","Social Login", "Profile Update", "Product List & Category wise products","Product Details", "Cart", "Checkout", "Notifications", "User Profile", "User Orders", "Feedback", "Share App", "Contact", "About", "Privacy Policy", "Address", "Notification & Notification Details", "Forget Password with Mobile OTP verification", "User can choice multiple payment options Cash on delivery", "Pickup Myself", "Pay with Online(Razorpay)", "User can choice date and time slot", "User can check the order details status Complete", "Processing", "Pending and history of orders", "User can Rate a Product Delivery Review", "Easy and Understand Design", "Unique and Beautiful UI","Multi language support"]
            },
            {
                "header": "Admin Panel Features",
                "items": ["Admin can see the Total Product, Total Area, Total Time-slot, Total Banner, Total Customer, Pending Order, Complete Order, Cancelled Order, Customer Rating, Total Feedback, Total Sales", "Admin can create unlimited Categories & Sub Categories", "Admin can create unlimited Banner", "Admin can create unlimited Time-slot", "Admin can create unlimited Area with Delivery Charges", "Admin can add/upload/delete Products", "Admin can select popular product", "Admin can Send Notification", "Admin can Create unlimited notification template and add/upload/delete template", "Admin can Send Notification once product added", "Admin can check customer product rating with Very Good, Good, Not Good", "Admin can check feedback with rating", "Admin can change currency($,₹,€,£,¥ etc)", "Admin can change User/Customer status anytime with active or deactive", "Setting for OneSignal API and OTP verification API", "Admin can check and update the order status - Complate, Processing, Pending", "Fast, Optimized and Powerful admin", "Beautiful UI", "High Quality", "Top quality clean code", "Easy configuration","Admin can assign order to delivery boy","Admin can add/update/delete delivery boy","Multi language suppotr"]
            },
            {
                "header": "iOS, Android Mobile App for delivery",
                "items": ["Easy Sign in", "Sign in with Mobile number/Email", "Profile Update","See to list of Assign order", "Notifications", "User Profile", "Order Details with customer address and items details", "Add and Update order status"]
            }
        ],
        "visualInsights": [
            {
                "header": "#image1",
                "imgSrc": GroceryStoreImage1
            },
            {
                "header": "#image2",
                "imgSrc": GroceryStoreImage2
            },
            {
                "header": "#image3",
                "imgSrc": GroceryStoreImage3
            },
            {
                "header": "#image4",
                "imgSrc": GroceryStoreImage4
            }
        ],
        "links": ["#", "#", "#"]
    }
];