import { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

const BlogSection = ({ item }) => {
	const [oneHeight, setOneHeight] = useState();

	const htmlDecode = (data) => {
		var e = document.createElement("div");
		e.innerHTML = data;
		return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
	};

	const setSameHeight = () => {
		let soneHeight = document.getElementsByClassName("blogcard");
		let soneArray = [];
		for (let a = 0; a < soneHeight.length; a++) {
			soneArray.push(soneHeight[a].offsetHeight);
		}
		setOneHeight((oneHeight) => Math.max(...soneArray));
	};

	useEffect(() => setSameHeight());

	return (
		<>
			<Link to={`/blogs/${item.slug}`}>
				<Card className="blogcard" style={{ height: `${oneHeight}px` }}>
					<Card.Img variant="top" src={item.mainImage} fluid />
					<Card.Body>
						<Card.Title className="blog-date fw-bold">
							{moment(item.publishedAt).format("DD MMM YYYY")}
						</Card.Title>
						<Card.Title className="blog-title fw-bold">
							<h1>{item.title}</h1>
						</Card.Title>
						<Card.Text
							dangerouslySetInnerHTML={{ __html: htmlDecode(item.content) }}
							className="blog-content mb-3"
						/>
						<small className="blog-time">{item.minread} min. read</small>
					</Card.Body>
				</Card>
			</Link>
		</>
	);
};

export default BlogSection;
