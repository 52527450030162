import { useEffect, useState, createContext } from 'react';


const ScrollContext = () => {

    const [data, setData] = useState({
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0
    });

    useEffect(() => {
        const handleScroll = () => {
            setData((last) => {
                return {
                    x: window.scrollX,
                    y: window.scrollY,
                    lastX: last.x,
                    lastY: last.y
                };
            });
        };

        handleScroll();
        window.addEventListener("scroll", handleScroll);
        
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    return data
}

export default ScrollContext

export const ScrollContent = createContext(null);