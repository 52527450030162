import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { ProductSMHead, ProductMainHeader, ProductMainParagraph, ProductMainButton } from '../components';

const ProductMainSection = (props) => {

    const { smTitle, mainHeader, pContent, buttonLabel, headerColor } = props;

    return (
        <React.Fragment>
            <ProductSMHead heading={smTitle} />
            <ProductMainHeader heading={mainHeader} headerColor={headerColor} />
            <Row>
                <Col lg={8} md={8} sm={8} xs={12}>
                    <ProductMainParagraph content={pContent} />
                </Col>
            </Row>
            <ProductMainButton label={buttonLabel} isLink={true} />
        </React.Fragment>
    )
}

export default ProductMainSection
