import React, { useRef, useState } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import Slider from "react-slick";

import {
	COMPANYLOGO,
	CONTACTFORM_URL,
	ldJson,
	DOMAIN,
} from "../utils/constants";
import { CONTACT_PAGE } from "../utils/keyWords";
import {
	INSTA_URL,
	SKYPE_URL,
	LINKEDIN_URL,
	FACEBOOK_URL,
	TWITTER_URL,
} from "../utils/constants";
import {
	Layout,
	Located,
	FooterLinks,
	NumberSection,
	LetsCraftSection,
	Testimonial,
	SmLink,
} from "../components";

import TWITTER from "../assets/images/social-icons/twitter@2x.png";
import FACEBOOK from "../assets/images/social-icons/facebook-icon-black@2x.png";
import INSTAGRAM from "../assets/images/social-icons/Instagram-icon-black@2x.png";
import LINKDIN from "../assets/images/social-icons/linkedIn-icon-black@2x.png";
import SKYPE from "../assets/images/social-icons/skype-icon-black@2x.png";
import BLURRING from "../assets/images/blur-ring@2x.png";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const leftSettings = {
	dots: false,
	infinite: true,
	autoplay: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	speed: 4000,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	variableWidth: true,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const Contact = (props) => {
	const [fullname, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [lookingfor, setLookingFor] = useState("");
	const [error, setError] = useState({});
	const [isDisabled, setIsDisabled] = useState(false);

	const { refId } = props;
	const contactInfo = useRef();

	const scrollTOSection = (refId) => {
		refId.current.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
			inline: "start",
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		const error = {};
		const defaultError = "This fields is required.";

		if (!fullname) {
			error["fullname"] = defaultError;
			setError(error);
		}

		if (!email) {
			error["email"] = defaultError;
			setError(error);
		}

		if (!lookingfor) {
			error["lookingfor"] = defaultError;
			setError(error);
		}

		let isValidate = Object.keys(error).length;
		if (!isValidate) {
			let payload = { fullname, email, lookingfor };
			setIsDisabled(true);

			fetch(CONTACTFORM_URL, {
				method: "POST",
				body: JSON.stringify(payload),
				headers: {
					"Content-type": "application/json",
				},
			}).then(
				(response) => {
					if (response.status === 200) {
						toast.success("Thank you! We will contact you soon.");
					}
					setIsDisabled(false);
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};

	ReactGA.pageview("ContactUsPage");

	return (
		<React.Fragment>
			<Helmet>
				<title>Contact Nxinfoway for Web and Mobile App Development</title>
				<meta
					name="description"
					content="Please feel free to contact us for any kind of IT services: Mobile and Web Development, Ecommmerce Solution etc. Email us: info@nxinfoway.com"
					data-react-helmet="true"
				/>
				<meta
					name="keywords"
					content="mobile application development company India, PHP web development company, iPhone apps development, mobile app development, android app development"
					data-react-helmet="true"
				/>
				<meta
					property="og:title"
					content="Contact Nxinfoway for Web and Mobile App Development"
					data-react-helmet="true"
				/>
				<meta
					property="og:description"
					content="Please feel free to contact us for any kind of IT services: Mobile and Web Development, Ecommmerce Solution etc. Email us: info@nxinfoway.com"
					data-react-helmet="true"
				/>
				<meta name="language" content="english" data-react-helmet="true" />
				<meta name="rating" content="general" data-react-helmet="true" />
				<meta name="distribution" content="global" data-react-helmet="true" />
				<meta property="og:locale" content="en_US" data-react-helmet="true" />
				<meta property="og:type" content="website" data-react-helmet="true" />
				<meta
					property="og:site_name"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta itemprop="name" content="Nxinfoway" data-react-helmet="true" />
				<meta property="og:url" content={DOMAIN} />
				<meta
					name="twitter:card"
					content="summary_large_image"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:site"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:creator"
					content="@nxinfoway"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:title"
					content="Contact Nxinfoway for Web and Mobile App Development"
					data-react-helmet="true"
				/>
				<meta
					name="twitter:description"
					content="Please feel free to contact us for any kind of IT services: Mobile and Web Development, Ecommmerce Solution etc. Email us: info@nxinfoway.com"
					data-react-helmet="true"
				/>
				<meta
					name="google-site-verification"
					content="ADWxvEJD6xocmBYTV0Ft93Dp6Blmd3Fb23CFqC6nI3k"
					data-react-helmet="true"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta
					content={`${DOMAIN}static/media/LogoNavigation.f0afe9f7.svg`}
					itemprop="image"
					data-react-helmet="true"
				/>
				<meta content={DOMAIN} itemprop="url" data-react-helmet="true" />
				<meta name="geo.region" content="IN-GJ" data-react-helmet="true" />
				<meta
					name="geo.placename"
					content="Ahmedabad"
					data-react-helmet="true"
				/>
				<meta
					name="geo.position"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="ICBM"
					content="23.1371428;72.5527651"
					data-react-helmet="true"
				/>
				<meta
					name="classification"
					content="Digital Marketing Agency,Mobile Development, Web Design, Web development"
					data-react-helmet="true"
				/>
				<meta
					name="GOOGLEBOT"
					content="index, follow"
					data-react-helmet="true"
				/>
				<meta name="Robots" content="index, follow" data-react-helmet="true" />
				<meta
					name="Search Engine"
					content="https://www.google.com"
					data-react-helmet="true"
				/>
				<meta name="OWNER" content="Nxinfoway" data-react-helmet="true" />
				<meta
					http-equiv="author"
					content="Nxinfoway"
					data-react-helmet="true"
				/>
				<meta name="copyright" content="Nxinfoway" data-react-helmet="true" />
				<meta name="expires" content="Never" data-react-helmet="true" />
				<meta name="RATING" content="General" data-react-helmet="true" />
				<meta name="REVISIT-AFTER" content="1 day" data-react-helmet="true" />

				<script type="application/ld+json">{JSON.stringify(ldJson)}</script>
			</Helmet>
			<Layout>
				<div className="section service head contact" ref={refId}>
					<Container fluid="xl">
						<Row>
							<Col lg={10} md={10} sm={10} xs={12}>
								<div className="wrapper pe-lg-5 pb-lg-5">
									<h1>Get in Touch.</h1>
									<p className="title">
										Our teams are dedicated to projects for extended periods of
										time. So that our clients get the best results. And that's
										why we are open for new collaboration where our team members
										work as your own team members who just happen to be remote.
									</p>
								</div>
							</Col>
						</Row>
						<Row className="form-wrapper">
							<Col lg={6} md={6} sm={6} xs={12}>
								<div className="form-section">
									<h4 className="mb-4 d-none d-sm-block">
										We'll make time to interact with you!
									</h4>
									<Form>
										<Row>
											<Col lg={6} md={6} sm={6} xs={12}>
												<Form.Group className="mb-3">
													<Form.Control
														type="text"
														name="fullname"
														placeholder="Your name"
														size="lg"
														onChange={(e) => setFullName(e.target.value)}
													/>
													<p className="text-danger">
														{error.fullname ? error.fullname : ""}
													</p>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={8} md={8} sm={8} xs={12}>
												<Form.Group className="mb-3">
													<Form.Control
														type="text"
														name="email"
														placeholder="Your email"
														size="lg"
														onChange={(e) => setEmail(e.target.value)}
													/>
													<p className="text-danger">
														{error.email ? error.email : ""}
													</p>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={10} md={10} sm={10} xs={12}>
												<Form.Group className="mb-3">
													<Form.Control
														as="textarea"
														rows={4}
														placeholder="What are you looking for?"
														size="lg"
														name="lookingfor"
														onChange={(e) => setLookingFor(e.target.value)}
													/>
													<p className="text-danger">
														{error.lookingfor ? error.lookingfor : ""}
													</p>
												</Form.Group>
											</Col>
										</Row>
										<Button
											variant="danger"
											size="lg"
											type="submit"
											onClick={onSubmit}
											disabled={isDisabled}
										>
											Sent Inquiry
										</Button>
									</Form>
								</div>
							</Col>
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row>
									<Col
										lg={{ span: 9, offset: 3 }}
										md={{ span: 9, offset: 3 }}
										sm={{ span: 9, offset: 3 }}
										xs={12}
									>
										<div
											className="address-section"
											style={{ marginTop: "40px" }}
										>
											<Image
												src={BLURRING}
												className="bgimg d-none d-sm-block"
											/>
											<div className="address-info">
												<h5 className="mb-3">Email</h5>
												<p className="mb-4">info@nxinfoway.com</p>
												<h5>Call</h5>
												<p className="mb-1">+91 982 591 0808</p>
												<p className="pb-4">Availability: IST 8 am TO 11 pm</p>
												<ul className="list-inline">
													<li
														className="list-inline-item"
														style={{ paddingRight: "20px", marginRight: "0px" }}
													>
														<a
															href={SKYPE_URL}
															target="_blank"
															rel="noreferrer"
														>
															<Image src={SKYPE} width="40" height="40" />
														</a>
													</li>
													<li
														className="list-inline-item"
														style={{ paddingRight: "20px", marginRight: "0px" }}
													>
														<a
															href={LINKEDIN_URL}
															target="_blank"
															rel="noreferrer"
														>
															<Image src={LINKDIN} width="40" height="40" />
														</a>
													</li>
													<li
														className="list-inline-item"
														style={{ paddingRight: "20px", marginRight: "0px" }}
													>
														<a
															href={FACEBOOK_URL}
															target="_blank"
															rel="noreferrer"
														>
															<Image src={FACEBOOK} width="40" height="40" />
														</a>
													</li>
													<li
														className="list-inline-item"
														style={{ paddingRight: "20px", marginRight: "0px" }}
													>
														<a
															href={TWITTER_URL}
															target="_blank"
															rel="noreferrer"
														>
															<Image src={TWITTER} width="40" height="40" />
														</a>
													</li>
													<li
														className="list-inline-item"
														style={{ paddingRight: "20px", marginRight: "0px" }}
													>
														<a
															href={INSTA_URL}
															target="_blank"
															rel="noreferrer"
														>
															<Image src={INSTAGRAM} width="40" height="40" />
														</a>
													</li>
												</ul>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
					<div className="statistics">
						<Container fluid="xl">
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="content">
									<Row>
										<Col
											lg={{ span: 8, offset: 2 }}
											md={{ span: 8, offset: 2 }}
											sm={{ span: 8, offset: 2 }}
											xs={12}
										>
											<NumberSection />
										</Col>
									</Row>
								</div>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className="company-logos">
									<Slider {...leftSettings}>
										{COMPANYLOGO &&
											COMPANYLOGO.map((item, index) => {
												return (
													<div className="" key={index}>
														<img
															src={item}
															alt={item}
															width="260"
															height="120"
														/>
													</div>
												);
											})}
									</Slider>
								</div>
							</Col>
						</Container>
					</div>
				</div>
				<div className="section service about">
					<div className="locations">
						<Container fluid="xl">
							<Located />
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="simple-tags text-center mt-4">
										{CONTACT_PAGE &&
											CONTACT_PAGE.map((item, index) => {
												return <span key={index}>{item}</span>;
											})}
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				<LetsCraftSection />
				<div className="section greenbg client-testimonial">
					<Container fluid="xl">
						<div className="sectionHead pb-lg-4">What clients says</div>
						<div className="mt-5 mb-5 mx-2">
							<Testimonial />
						</div>
						<Row className="pt-5 pb-5 mt-5">
							<Col lg={6} md={6} sm={6} xs={12} className="text-start">
								<SmLink
									url="#"
									onClick={() => scrollTOSection(contactInfo)}
									textAlign="text-start"
									label="Let’s Discuss Your Project"
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="section darkbg flinks footer-data">
					<Container fluid="xl">
						<div className="footer-links">
							<FooterLinks />
						</div>
					</Container>
				</div>
			</Layout>
		</React.Fragment>
	);
};

export default Contact;
