import React from "react";

import { Row, Col, Image } from "react-bootstrap";

import AddressMap1 from "../assets/images/ahmedabad-address@2x.png";
import AddressMap2 from "../assets/images/rajkot-address@2x.png";
import AddressMap3 from "../assets/images/Montevideo-Uruguay@2x.png";

const Located = () => {
	return (
		<React.Fragment>
			<h2 className="sectionHead pb-lg-4 pb-3">Located</h2>
			<Row>
				<Col lg={6} md={6} sm={6} xs={12} className="mb-lg-4 mb-3">
					<div className="address-card">
						<Row style={{ height: "100%" }}>
							<Col lg={6} md={6} sm={6} xs={5}>
								<Image src={AddressMap1} alt="address" fluid />
							</Col>
							<Col lg={6} md={6} sm={6} xs={7}>
								<div
									className="py-lg-4 px-lg-2 py-3"
									style={{ height: "100%" }}
								>
									<h2>Ahmedabad - India</h2>
									<address>
										601, Pehel Lake View,
										<br />
										Nr. Vaishnodevi Circle.
										<br />
										Ahmedabad - 382421
										<br />
										Gujarat, India
									</address>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col lg={6} md={6} sm={6} xs={12} className="mb-lg-4 mb-3">
					<div className="address-card">
						<Row style={{ height: "100%" }}>
							<Col lg={6} md={6} sm={6} xs={5}>
								<Image src={AddressMap2} alt="address" fluid />
							</Col>
							<Col lg={6} md={6} sm={6} xs={7}>
								<div
									className="py-lg-4 px-lg-2 py-3"
									style={{ height: "100%" }}
								>
									<h2>Rajkot - India</h2>
									<address>
										305, Shivalay,
										<br />
										Mavdi Crossroad,
										<br />
										Rajkot - 360004,
										<br />
										Gujarat, India
									</address>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col lg={6} md={6} sm={6} xs={12} className="mb-lg-4 mb-3">
					<div className="address-card">
						<Row style={{ height: "100%" }}>
							<Col lg={6} md={6} sm={6} xs={5}>
								<Image src={AddressMap3} alt="address" fluid />
							</Col>
							<Col lg={6} md={6} sm={6} xs={7}>
								<div
									className="py-lg-4 px-lg-2 py-3"
									style={{ height: "100%" }}
								>
									<h2>Montevideo - Uruguay</h2>
									<address>
										Luis Alberto de Herrera
										<br />
										3701 / 301,
										<br />
										Montevideo - Uruguay,
										<br />
										South America
									</address>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default Located;
