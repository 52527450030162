import React from "react";

import Slider from "react-slick";
import { TESTIMONIALS } from "../utils/constants";

const testimonial = {
	dots: true,
	arrows: false,
	speed: 500,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const Testimonial = () => {
	return (
		<Slider {...testimonial}>
			{TESTIMONIALS &&
				TESTIMONIALS.map((item, index) => {
					return (
						<div className="tesimonial" key={index}>
							<h3 className="mb-3">{item.content}</h3>
							<div className="d-flex">
								<div className="flex-shrink-0">
									<img src={item.image} alt={item.fullname} />
								</div>
								<div className="flex-grow-1 ms-3">
									<h5 className="text-white m-0">{item.fullname}</h5>
									<p className="text-white m-0">{item.designation}</p>
								</div>
							</div>
						</div>
					);
				})}
		</Slider>
	);
};

export default Testimonial;
