import React from "react";

import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Layout, FooterLinks } from "../components";

const PageNotFound = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>404 - Not found page | Nxinfoway</title>
			</Helmet>
			<Layout>
				<div className="section service head py-5">
					<Container fluid="xl">
						<div className="wrapper text-center">
							<h1>404</h1>
							<p className="title mb-5">Oops! That page can't be found.</p>
							<Link to="/" className="default-button">
								Back to Home
							</Link>
						</div>
					</Container>
				</div>
				<div className="section darkbg flinks">
					<Container fluid="xl">
						<div className="footer-links">
							<FooterLinks />
						</div>
					</Container>
				</div>
			</Layout>
		</React.Fragment>
	);
};

export default PageNotFound;
