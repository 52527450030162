import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ScrollToTop } from "./components";
import { BlogProvider } from "./context/blogContext";
import {
	Home,
	Services,
	Contact,
	OurWork,
	Product,
	Project,
	About,
	Development,
	Blogs,
	BlogDetail,
	PageNotFound,
	Developer,
	ServiceDetail,
} from "./pages";

import ReactGA from "react-ga";

const App = () => {
	ReactGA.initialize("UA-219507959-1");
	ReactGA.pageview("HomePage");

	return (
		<BlogProvider>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/services" element={<Services />} />
					<Route exact path="/service/:slug" element={<ServiceDetail />} />
					<Route exact path="/contact" element={<Contact />} />
					<Route exact path="/our-work" element={<OurWork />} />
					<Route exact path="/product/:slug" element={<Product />} />
					<Route exact path="/project/:slug" element={<Project />} />
					<Route exact path="/blogs" element={<Blogs />} />
					<Route exact path="/blogs/:slug" element={<BlogDetail />} />
					<Route exact path="/about" element={<About />} />
					<Route exact path="/:slug" element={<Development />} />
					<Route exact path="/hire-:slug" element={<Developer />} />
					<Route exact path="/404" element={<PageNotFound />} />
					<Route exact path="/*" element={<PageNotFound />} />
				</Routes>
			</BrowserRouter>
		</BlogProvider>
	);
};

export default App;
