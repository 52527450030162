import React, { useEffect } from 'react';

const ScrollToTop = () => {

    useEffect(() => {
        const body = document.querySelector('#root');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    }, []);

    return null;
}

export default ScrollToTop;