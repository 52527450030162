import { createContext, useEffect, useState } from "react";

import { BLOGS_URL } from "../utils/constants";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
	const [blogs, setBlogs] = useState([]);

	useEffect(() => {
		fetch(BLOGS_URL, {
			method: "GET",
			headers: {
				"Access-Control-Allow-Credentials": true,
				"content-type": "application/json",
				Accept: "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setBlogs(data.data);
			});
	}, []);

	return (
		<BlogContext.Provider value={{ blogs }}>{children}</BlogContext.Provider>
	);
};

export default BlogContext;
