import React from "react";

import { Row, Col } from "react-bootstrap";

const NumberSection = () => {
	return (
		<Row>
			<Col lg={4} md={4} sm={4} xs={6}>
				<div className="exp">
					<h2>9+</h2>
					<p>Years of Experience</p>
				</div>
			</Col>
			<Col lg={4} md={4} sm={4} xs={6}>
				<div className="delivered">
					<h2>150+</h2>
					<p>Projects Delivered</p>
				</div>
			</Col>
			<Col lg={4} md={4} sm={4} xs={12}>
				<div className="happy-clients">
					<h2>100+</h2>
					<p>Happy Clients</p>
				</div>
			</Col>
		</Row>
	);
};

export default NumberSection;
