import FashionStore from "../assets/images/fashion-store@2x.png";
import FoodOnline from "../assets/images/food-online@2x.png";
import GroceryStore from "../assets/images/grocery-store@2x.png";
import BuySell from "../assets/images/buy-sell@2x.png";
import HappyGrocery from "../assets/images/happy-grocery@2x.png";
import AmGroup from "../assets/images/am-group@2x.png";
import DoConnect from "../assets/images/doconnect@2x.png";

import HowWeDo1 from "../assets/images/how-we-do/sec-1@3x.png";
import HowWeDo2 from "../assets/images/how-we-do/sec-2@3x.png";
import HowWeDo3 from "../assets/images/how-we-do/sec-3@3x.png";
import HowWeDo4 from "../assets/images/how-we-do/sec-4@3x.png";

import WhatWeDo1 from "../assets/images/what-we-do/sec-1@3x.png";
import WhatWeDo2 from "../assets/images/what-we-do/sec-2@3x.png";
import WhatWeDo3 from "../assets/images/what-we-do/sec-3@3x.png";
import WhatWeDo4 from "../assets/images/what-we-do/sec-4@3x.png";
import WhatWeDo5 from "../assets/images/what-we-do/sec-5@3x.png";

import android from "../assets/images/technologies/android.svg";
import angular from "../assets/images/technologies/angular.svg";
import codeigniter from "../assets/images/technologies/codeigniter.svg";
import css from "../assets/images/technologies/css.svg";
import dotnet from "../assets/images/technologies/dotnet.svg";
import drupal from "../assets/images/technologies/drupal.svg";
import firebase from "../assets/images/technologies/firebase.svg";
import flutter from "../assets/images/technologies/flutter.svg";
import html from "../assets/images/technologies/html.svg";
import ios from "../assets/images/technologies/ios.svg";
import java from "../assets/images/technologies/java.svg";
import jquery from "../assets/images/technologies/jquery.svg";
import laravel from "../assets/images/technologies/laravel.svg";
import magento from "../assets/images/technologies/magento.svg";
import mongodb from "../assets/images/technologies/mongodb.svg";
import mysql from "../assets/images/technologies/mysql.svg";
import nodejs from "../assets/images/technologies/nodejs.svg";
import oracle from "../assets/images/technologies/oracle.svg";
import php from "../assets/images/technologies/php.svg";
import reactjs from "../assets/images/technologies/reactjs.svg";
import reactnative from "../assets/images/technologies/react-native.svg";
import realm from "../assets/images/technologies/realm.svg";
import shopify from "../assets/images/technologies/shopify.svg";
import wordpress from "../assets/images/technologies/wordpress.svg";

import client from "../assets/images/clients/client-picture@2x.png";
import client1 from "../assets/images/clients/client_jose.jpg";
import client2 from "../assets/images/clients/client_siyabonga.jpg";
import client3 from "../assets/images/clients/client_jeffry.png";

import appleLogo from "../assets/images/apple-logo.svg";
import androidLogo from "../assets/images/android-logo.svg";
import crossPlatform from "../assets/images/cross-platform.svg";

import database from "../assets/images/database.svg";
import frontend from "../assets/images/frontend.svg";
import web from "../assets/images/web.svg";

import services from "../assets/images/services.svg";
import tools from "../assets/images/tools.svg";

import lifeiswine from "../assets/images/portfolios/life-is-wine@3x.png";
import buysell from "../assets/images/portfolios/buy-sell@3x.png";
import cityride from "../assets/images/portfolios/city-ride@3x.png";
import doconnect from "../assets/images/portfolios/doconnect@3x.png";
import fashionstore from "../assets/images/portfolios/fashion-store@3x.png";
import foodonline from "../assets/images/portfolios/food-online@3x.png";
import greenstore from "../assets/images/portfolios/green-store@3x.png";
import happygrocery from "../assets/images/portfolios/happy-grocery@3x.png";
import quizapp from "../assets/images/portfolios/quiz-app@3x.png";
import zoogla from "../assets/images/portfolios/zoogla@3x.png";
import grocerystore from "../assets/images/portfolios/grocery-store@3x.png";
import getintouch from "../assets/images/portfolios/get-in-touch@3x.png";
import happyHome from "../assets/images/portfolios/happy-home@2x.png";
import chamberLink from "../assets/images/portfolios/chember-link@2x.png";
import efremov from "../assets/images/portfolios/efremov@3x.png";

import sales from "../assets/images/branding/sales@2x.png";
import salestool from "../assets/images/branding/sales-tool@2x.png";
import sc from "../assets/images/branding/sc@2x.png";
import sg from "../assets/images/branding/sg@2x.png";
import simpler from "../assets/images/branding/simpler@2x.png";
import theme from "../assets/images/branding/theme@2x.png";
import tsl from "../assets/images/branding/tsl@2x.png";
import weLive from "../assets/images/branding/we-live@2x.png";

import custom from "../assets/images/showcase/custom@2x.png";
import group1 from "../assets/images/showcase/group-1@2x.png";
import group from "../assets/images/showcase/group@2x.png";
import mask from "../assets/images/showcase/mask@2x.png";
import mockup from "../assets/images/showcase/mockup@2x.png";
import perspective from "../assets/images/showcase/perspective-shot@2x.png";
import splash from "../assets/images/showcase/splash@2x.png";
import walkthrough from "../assets/images/showcase/walkthrough@2x.png";

import slide1 from "../assets/images/jumbotron-images/sec-1@3x.png";
import slide2 from "../assets/images/jumbotron-images/sec-2@3x.png";
import slide3 from "../assets/images/jumbotron-images/sec-3@3x.png";
import slide4 from "../assets/images/jumbotron-images/sec-4@3x.png";
import slide5 from "../assets/images/jumbotron-images/sec-5@3x.png";

import amgroupLogo from "../assets/images/comapny-logo/amgroup@2x.png";
import bookingappLogo from "../assets/images/comapny-logo/bookingapp@2x.png";
import buysaleLogo from "../assets/images/comapny-logo/buysale@2x.png";
import cityrideLogo from "../assets/images/comapny-logo/cityride@2x.png";
import doconnectLogo from "../assets/images/comapny-logo/doconnect@2x.png";
import fashionstoreLogo from "../assets/images/comapny-logo/fashionstore@2x.png";
import foodonlineLogo from "../assets/images/comapny-logo/foodonline@2x.png";
import greenstoreLogo from "../assets/images/comapny-logo/greenstore@2x.png";
import happygroceryLogo from "../assets/images/comapny-logo/happygrocery@2x.png";
import lifeswineLogo from "../assets/images/comapny-logo/lifeswine@2x.png";
import surfboardLogo from "../assets/images/comapny-logo/surfboard@2x.png";
import zooglaLogo from "../assets/images/comapny-logo/zoogla@2x.png";

export const INSTA_URL = "https://www.instagram.com/nxinfoway/";
export const SKYPE_URL = "https://join.skype.com/invite/uI9eDntrWPyH";
export const LINKEDIN_URL = "https://www.linkedin.com/company/nxinfoway";
export const FACEBOOK_URL =
	"https://www.facebook.com/nxinfoway-102772285635732";
export const TWITTER_URL = "https://twitter.com/nxinfoway";

export const DOMAIN = "https://www.nxinfoway.com/";
// export const DOMAIN = "http://localhost:3000/";
export const CONTACTFORM_URL = `${DOMAIN}contactus.php`;
export const HIRERESOURCES_URL = `${DOMAIN}hire_resources.php`;
export const BLOGS_URL = `${DOMAIN}blog.php`;
export const BLOG_DETAIL_URL = `${BLOGS_URL}?slug=`;

export let CURRENT_YEAR = new Date().getFullYear();

export const HOME_TEXT = [
	{
		heading: "Crafting",
		scrollHeading: [
			"Digital Products",
			"Consumer Products",
			"Enterprise Products",
		],
		image: slide1,
	},
	{
		heading: "We Provide",
		scrollHeading: ["Ecommerce Solutions", "B2B Solutions", "B2C Solutions"],
		image: slide2,
	},
	{
		heading: "Crafting",
		scrollHeading: ["Beautiful Designs", "UX UI designs", "Branding and Media"],
		image: slide3,
	},
	{
		heading: "Developing",
		scrollHeading: [
			"Mobile Applications",
			"Web Applications",
			"Cross-Platform Apps",
		],
		image: slide4,
	},
	{
		heading: "Solutions by",
		scrollHeading: ["Research", "Analysis", "Teamwork"],
		image: slide5,
	},
];

export const HOME_PAGE_SLIDES = [
	{
		smTitle: "E-commerce App",
		mainHeader: "Fashion Store",
		pContent:
			"A complete eCommerce multi vendor solution with beautiful and modern design, Being native to both mobile platforms it delivers fast and smooth performance.",
		buttonLabel: "View More >",
		headerColor: "text-pink",
		mainImage: FashionStore,
		id: "fashion-store",
	},
	{
		smTitle: "Food Delivery App",
		mainHeader: "Food Online",
		pContent:
			"Order your favourite foods from “food online” app, access to almost every restaurant in your area or city in one app.",
		buttonLabel: "View More >",
		headerColor: "text-violet",
		mainImage: FoodOnline,
		id: "food-online",
	},
	{
		smTitle: "Online Grocery App",
		mainHeader: "Grocery App",
		pContent:
			"A simplified wonderful solution for your Grocery business! Single & Multi vendor! Open your online grocery store today!",
		buttonLabel: "View More >",
		headerColor: "text-yellow",
		mainImage: GroceryStore,
		id: "grocery-app",
	},
	{
		smTitle: "Service App",
		mainHeader: "Buy & Sell",
		pContent:
			"A whole new way to Buy and Sell old stuff direct from your home and Book a home cleaning service or book a hair stylist, all these happens in one app.",
		buttonLabel: "View More >",
		headerColor: "text-blue",
		mainImage: BuySell,
		id: "buy-sell",
	},
	{
		smTitle: "E-commerce App",
		mainHeader: "Happy Grocery",
		pContent:
			"Newest e-grocery platform developed for Nepal. Coming up with widest range of sellers in all major cities, Single & Multi vendor!",
		buttonLabel: "View More >",
		headerColor: "text-red",
		mainImage: HappyGrocery,
		id: "happy-grocery",
	},
	{
		smTitle: "Business App",
		mainHeader: "Life is Wine",
		pContent:
			"Chain of restaurants and bars located in Russia, connected via a single app from AMGroup.",
		buttonLabel: "View More >",
		headerColor: "text-black",
		mainImage: AmGroup,
		id: "life-is-wine",
	},
	{
		smTitle: "Online Consultation App",
		mainHeader: "Doconnect",
		pContent:
			"Consult with your choice of medical experts around you, Book appointments,",
		buttonLabel: "View More >",
		headerColor: "text-lightGreen",
		mainImage: DoConnect,
		id: "doconnect",
	},
];

export const WHAT_WE_DO_CARDS = [
	{
		borderColor: "ba1eff",
		image: WhatWeDo1,
		headerColor: "text-ba1fff",
		header: "Mobile Apps",
	},
	{
		borderColor: "00c78a",
		image: WhatWeDo2,
		headerColor: "text-00c78a",
		header: "Web Development",
	},
	{
		borderColor: "ff2e75",
		image: WhatWeDo3,
		headerColor: "text-ff2e75",
		header: "UX/UI Design",
	},
	{
		borderColor: "2772ff",
		image: WhatWeDo4,
		headerColor: "text-2772ff",
		header: "Hire Resources",
	},
	{
		borderColor: "2772ff",
		image: WhatWeDo5,
		headerColor: "text-bdb9c7",
		header: "eCommerce Solutions",
	},
];

export const HOW_WE_DO_CARDS = [
	{
		image: HowWeDo1,
		header: "Define",
		headerSm: "Research, Analysis, Strategy, Ideation Process.",
	},
	{
		image: HowWeDo2,
		header: "Design",
		headerSm:
			"UX, UI, illustration, Prototyping, Interaction, Branding, Media.",
	},
	{
		image: HowWeDo3,
		header: "Develop",
		headerSm: "Front-end, Back-end, Programming, Testing, QA.",
	},
	{
		image: HowWeDo4,
		header: "Deliver",
		headerSm: "Product ready, Store submission, Project delivery.",
	},
];

export const TECHNOLOGIES_ONE = [
	{
		name: "iOS",
		icon: ios,
	},
	{
		name: "Android",
		icon: android,
	},
	{
		name: "Flutter",
		icon: flutter,
	},
	{
		name: "React js",
		icon: reactjs,
	},
	{
		name: "Angular js",
		icon: angular,
	},
	{
		name: "React native",
		icon: reactnative,
	},
	{
		name: "jQuery",
		icon: jquery,
	},
	{
		name: "HTML",
		icon: html,
	},
	{
		name: "CSS",
		icon: css,
	},
	{
		name: "Mongo DB",
		icon: mongodb,
	},
	{
		name: "My SQL",
		icon: mysql,
	},
	{
		name: "Oracle",
		icon: oracle,
	},
	{
		name: "Node.js",
		icon: nodejs,
	},
	{
		name: "Laravel",
		icon: laravel,
	},
	{
		name: "CodeIgniter",
		icon: codeigniter,
	},
	{
		name: "PHP",
		icon: php,
	},
	{
		name: ".NET",
		icon: dotnet,
	},
	{
		name: "Java",
		icon: java,
	},
	{
		name: "Wordpress",
		icon: wordpress,
	},
	{
		name: "Magento",
		icon: magento,
	},
	{
		name: "Drupal",
		icon: drupal,
	},
	{
		name: "Shopify",
		icon: shopify,
	},
	{
		name: "Firebase",
		icon: firebase,
	},
	{
		name: "Realm",
		icon: realm,
	},
];

export const TESTIMONIALS = [
	{
		content:
			"My experience with Nxinfoway was Awesome! They provided Really great communication and excellent Mobile and Web development services for my products.",
		image: client1,
		fullname: "Jose Gabriel Ferreira Bueno",
		designation:
			"CTO - Manager de Tecnología en Poseidón Technologies - Inteligencia Marítima,Uruguay",
	},
	{
		content:
			"Working with Nxinfoway was a great experience. The team was very communicative and went above and beyond for our needs! They provided excellent Mobile and Web development services for zoogla product",
		image: client2,
		fullname: "Siyabonga Matsebula",
		designation: "CEO, Zoogla, UK",
	},
	{
		content:
			"Nxinfoway completed and delivered the project on time. They provided Really great communication and excellent work quality. The client was satisfied with the quality of the project results.",
		image: client3,
		fullname: "Jeffry Musayón",
		designation: "IT Lead, MIT, Peru",
	},
	{
		content:
			"NXI is helping us create a well-rounded and engaging experience for all of our customers. They feel like part of our internal team, and we have a great working relationship.",
		image: client,
		fullname: "Perry Bradley",
		designation: "CEO, ATOZ Corporation, USA",
	},
];

export const MOBILEAPP_SERVICES = [
	{
		icon: appleLogo,
		title: "iOS",
		tags: [
			{ href: "/ios-development", text: "Swift" },
			{ href: "/ios-development", text: "Xcode" },
		],
	},
	{
		icon: androidLogo,
		title: "Android",
		tags: [
			{ href: "/service/kotlin-app-development", text: "Kotlin" },
			{ href: "/android-development", text: "Java" },
			{ href: "/android-development", text: "Android studio" },
		],
	},
	{
		icon: crossPlatform,
		title: "Cross Platform",
		tags: [
			{ href: "/flutter-development", text: "Flutter" },
			{ href: "/reactnative-development", text: "React Native" },
		],
	},
];

export const WEBAPP_SERVICES = [
	{
		icon: frontend,
		title: "Front-end",
		tags: [
			{ href: "/reactjs-development", text: "React js" },
			{ href: "/angularjs-development", text: "Angular js" },
			{ href: "", text: "jQuery" },
			{ href: "", text: "HTML5" },
			{ href: "", text: "CSS" },
		],
	},
	{
		icon: web,
		title: "Web",
		tags: [
			{ href: "/nodejs-development", text: "Node.js" },
			{ href: "/laravel-development", text: "Laravel" },
			{ href: "/codeigniter-development", text: "CodeIgniter" },
			{ href: "/dot-net-development", text: ".NET" },
			{ href: "/java-development", text: "Java" },
			{ href: "/php-development", text: "PHP" },
			{ href: "/wordpress-development", text: "Worpress" },
			{ href: "/magento-development", text: "Magento" },
			{ href: "", text: "Shopify" },
			{ href: "", text: "Drupal" },
		],
	},
	{
		icon: database,
		title: "Database",
		tags: [
			{ href: "", text: "Mongo DB" },
			{ href: "", text: "My SQL" },
			{ href: "", text: "Firebase" },
			{ href: "", text: "Realm" },
			{ href: "", text: "Oracle" },
		],
	},
];

export const DESIGN_SERVICES = [
	{
		icon: services,
		title: "Services",
		tags: [
			{ href: "", text: "Wireframing" },
			{ href: "", text: "UX design" },
			{ href: "", text: "UI design" },
			{ href: "", text: "Design system" },
			{ href: "", text: "Interaction design" },
			{ href: "", text: "Prototyping" },
			{ href: "", text: "Animation" },
			{ href: "", text: "Illustration" },
			{ href: "", text: "Branding" },
			{ href: "", text: "Logo & Identity" },
			{ href: "", text: "Digital marketing" },
		],
	},
	{
		icon: tools,
		title: "Tools",
		tags: [
			{ href: "", text: "Xd" },
			{ href: "", text: "Figma" },
			{ href: "", text: "Sketch" },
			{ href: "", text: "Photoshop" },
			{ href: "", text: "Illustrator" },
			{ href: "", text: "After Effects" },
			{ href: "", text: "In-Design" },
			{ href: "", text: "Miro" },
			{ href: "", text: "Zeplin" },
		],
	},
];

export const INDUSTRY_SERVICES = [
	{
		icon: services,
		title: "Solutions and Services",
		tags: [
			{ href: "/service/ecommerce-app-development", text: "Ecommerce" },
			{ href: "/service/grocery-app-development", text: "Grocery" },
			{ href: "/service/ecommerce-app-development", text: "B2B" },
			{ href: "/service/ecommerce-app-development", text: "B2C" },
			{ href: "/service/healthcare-app-development-company", text: "Healthcare" },
			{ href: "/service/entertainment-app-development", text: "Entertainment" },
			{ href: "/service/transportation-app-development", text: "Transport" },
			{ href: "/service/social-media-app-development", text: "Social Networking" },
			{ href: "/service/game-app-development", text: "Utility" },
			{ href: "/service/fintech-app-development-company", text: "Fintech" },
			{ href: "", text: "Edtech" },
			{ href: "", text: "Personalisation" },
			{ href: "", text: "Information" },
			{ href: "", text: "News & Blogs" },
			{ href: "/service/game-app-development", text: "Games" },
			{ href: "/service/education-app-development", text: "Education" },
			{ href: "/service/real-estate-app-development-company", text: "Real eState" },
			{ href: "/service/transportation-app-development", text: "GPS & Navigation" },
			{ href: "/service/crm-development", text: "CRM Software" },
			{ href: "/service/erp-software-development", text: "ERP Software" },
			{ href: "", text: "AI & ML" },
			{ href: "", text: "AR & VR" },
			{ href: "", text: "Crypto" },
			{ href: "/service/food-delivery-app-development", text: "Food & Beverage" },
			{ href: "/service/sports-app-development", text: "Sports" },
			{ href: "/service/online-booking-app-development", text: "Booking Software" },
			{ href: "/service/event-app-development", text: "Event Software" },
			{ href: "/service/pos-software-development", text: "POS Software" },
			{ href: "/service/ondemand-app-development", text: "On-Demand" },
		],
	},
];

export const SOLUTIONS_SERVICES = [
	{
		icon: services,
		title: "Services",
		tags: [
			{ href: "", text: "E-Commerce Industry" },
			{ href: "", text: "Travel & Tourism Industry" },
			{ href: "", text: "UI design" },
			{ href: "", text: "Design system" },
			{ href: "", text: "Real Estate Industry" },
			{ href: "", text: "Hospitality Industry" },
			{ href: "", text: "Logistics Industry" },
			{ href: "", text: "Education Industry" },
			{ href: "", text: "Media & Entertainment" },
			{ href: "", text: "Health & Fitness" },
			{ href: "", text: "Information Technology" },
		],
	},
	{
		icon: services,
		title: "Services",
		tags: [
			{ href: "", text: "Fintech" },
			{ href: "", text: "Figma" },
			{ href: "", text: "Sketch" },
			{ href: "", text: "Photoshop" },
			{ href: "", text: "Illustrator" },
			{ href: "", text: "After Effects" },
			{ href: "", text: "In-Design" },
			{ href: "", text: "Miro" },
			{ href: "", text: "Zeplin" },
		],
	},
];

export const WORK_PROFILE = [
	{
		img: fashionstore,
		heading: "Fashion Store",
		content: "Multi-vendor ecommerce solution",
		type: "iOS, Android",
		link: "/product/fashion-store",
	},
	{
		img: lifeiswine,
		heading: "Life is Wine",
		content: "Business membership app",
		type: "iOS, Android",
		link: "/project/life-is-wine",
	},
	{
		img: buysell,
		heading: "Epress",
		content: "Buy, sell & services solution",
		type: "Android",
		link: "/project/buy-sell",
	},
	{
		img: grocerystore,
		heading: "Grocery Store",
		content: "Single & multi-vendor grocery solution",
		type: "iOS, Android, Web",
		link: "/product/grocery-store",
	},
	{
		img: happygrocery,
		heading: "Happy Grocery",
		content: "Multi-vendor grocery store",
		type: "iOS, Android",
		link: "/project/happy-grocery",
	},
	{
		img: happyHome,
		heading: "Happy Home",
		content: "Home Service provider",
		type: "Web",
		link: "/project/happy-home",
	},
	{
		img: chamberLink,
		heading: "Chamber Link",
		content: "Chamber Link for South US",
		type: "Web, iOS, Android",
		link: "/project/chamber-link",
	},
	{
		img: foodonline,
		heading: "Food Online",
		content: "Food Delivery Solution",
		type: "iOS, Android",
		link: "#",
	},
	{
		img: doconnect,
		heading: "Doconnect",
		content: "Online Consultation",
		type: "Mobile app",
		link: "#",
	},
	{
		img: zoogla,
		heading: "Zoogla",
		content: "Finance solutions",
		type: "iOS, Android, Design",
		link: "/project/zoogla",
	},
	{
		img: efremov,
		heading: "Efremov -  Online Jewelry Store",
		content: "Jewelry solutions",
		type: "iOS, Android, Design, Webiste",
		link: "/project/efremov-gold",
	},
	{
		img: cityride,
		heading: "City Ride",
		content: "Bike Booking App",
		type: "UX, UI design",
		link: "#",
	},
	{
		img: greenstore,
		heading: "Green Store",
		content: "Grocery web store",
		type: "Web, Design",
		link: "#",
	},
	{
		img: quizapp,
		heading: "Quiz app",
		content: "Puzzle app",
		type: "UX, UI design",
		link: "#",
	},
	{
		img: getintouch,
		heading: "",
		content: "",
		type: "",
		link: "#",
	},
];

export const BRANDING = [
	{
		img: sc,
		heading: "SC",
		content: "Branding",
		type: "Design",
	},
	{
		img: sg,
		heading: "SG",
		content: "Logo mark",
		type: "Design",
	},
	{
		img: weLive,
		heading: "We live",
		content: "Media",
		type: "Design",
	},
	{
		img: tsl,
		heading: "TSL",
		content: "Branding",
		type: "Design",
	},
	{
		img: salestool,
		heading: "Sales Tool",
		content: "Logo mark",
		type: "Design",
	},
	{
		img: theme,
		heading: "Theme",
		content: "Logo mark",
		type: "Design",
	},
	{
		img: simpler,
		heading: "Simpler",
		content: "Branding",
		type: "Design",
	},
	{
		img: sales,
		heading: "Sales",
		content: "Branding",
		type: "Design",
	},
];

export const SHOWCASES = [
	perspective,
	custom,
	group,
	splash,
	walkthrough,
	mockup,
	group1,
	mask,
];

export const COMPANYLOGO = [
	amgroupLogo,
	bookingappLogo,
	buysaleLogo,
	cityrideLogo,
	doconnectLogo,
	fashionstoreLogo,
	foodonlineLogo,
	greenstoreLogo,
	happygroceryLogo,
	lifeswineLogo,
	surfboardLogo,
	zooglaLogo,
];

export const SERVICE_OFFERS = [
	{
		heaing: "Gathering requirements & UX/UI app design",
		content:
			"our creative team of designers offers a full range of web and mobile app design services, from prototyping and graphic design to pixel-perfect UI and Intuitive user interfaces and beautiful designs to deliver delightful user experiences",
	},
	{
		heaing: "Quick development",
		content:
			"Our team with its rich expertise and diverse experience optimizes all processes and employs the best agile practices, leading to a reduction in custom software application development time.",
	},
	{
		heaing: "Quality product",
		content:
			"we keep making iterations in the product till it exactly aligns with what you had desired.",
	},
	{
		heaing: "Complete transparency",
		content:
			"Bug fixes, patching and updates for a robust, secure app and continuously high performance",
	},
];

export const ANDROID_SERVICE_OFFERS = [
	{
		heaing: "Custom application development",
		content:
			"Nxinfoway provide Custom Android applications uses Kotlin or Java as per product requirement",
	},
	{
		heaing: "Android app testing",
		content:
			"Rigorous testing and quality assurance to remove glitches and ensure high performance prvided nxinfoway",
	},
	{
		heaing: "UX/UI Android app design",
		content:
			"Nxinfoway provide Intuitive user interfaces and beautiful designs to deliver delightful user experiences",
	},
	{
		heaing: "Android app maintenance",
		content:
			"Bug fixes, patching and updates for a robust, secure app and continuously high performance",
	},
];

export const LARAVEL_SERVICE_OFFERS = [
	{
		heaing: "Laravel Customization & Integration",
		content:
			"Nxinfoway provideLaravel offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. Laravel gives a variety of helpful validation rules, and also accommodates your specifications too.",
	},
	{
		heaing: "E-commerce Development",
		content:
			"Nxinfoway provide best Laravel is an all-in-one e-commerce platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an e-commerce platform. ",
	},
	{
		heaing: "RESTful Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers. Create a route for the resourceful controller in routes/api.",
	},
	{
		heaing: "Maintenance and support",
		content:
			"Nxinfoway maintence website Bug fixes, patching and updates for a robust, secure web and continuously high performance",
	},
];

export const PHP_SERVICE_OFFERS = [
	{
		heaing: "Custom PHP Application",
		content:
			"Nxinfoway provide offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. We will provide Custom PHP Application & Custom PHP Product Development & Custom PHP Application Development.",
	},
	{
		heaing: "E-commerce Development & Migrating and Porting",
		content:
			"Nxinfoway provide best Php e-commerce platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an e-commerce platform & Migrating and Porting.",
	},
	{
		heaing: "RESTful Development & Responsive PHP Website Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers. Create a route for the resourceful controller in routes/api. nxinfoway offer to Responsive PHP Website Development.",
	},
	{
		heaing: "CMS & Components & Maintenance and support",
		content:
			"Nxinfoway maintence website Bug fixes, patching and updates for a robust, secure web and continuously high performance. we will offer Customization with CMS and Components & Developing Major Frameworks in PHP & Integrating with well-known Payment Gateways.",
	},
];

export const NODEJS_SERVICE_OFFERS = [
	{
		heaing: "Node.JS Consulting Services",
		content:
			"Nxinfoway provide offers to Professional consulting with Node JS development services and on-demand developers for development, customization, and maintenance of Node.JS apps.",
	},
	{
		heaing: "Node.JS Mobile And Web Development",
		content:
			"Nxinfoway provide best Developed lightweight, flexible, and high-performance apps under our Node.JS Android development services. Best in class Node JS web development company for strategizing and developing robust and performing apps.",
	},
	{
		heaing: "Node.JS API Development",
		content:
			"Created secure and ready-for-use REST API using Node.JS from scratch in quicker turnaround time. We offer best-in-class Node.JS development services to global clients.",
	},
	{
		heaing: "Node.JS Migration & Maintenance and Support",
		content:
			"Migrated complex applications built on different platforms to Node.JS and automated DB migration in MongoDB using Node.JS. Top Node JS development company with SLA-based support for FE/BE maintenance, app updates, and new releases.",
	},
];

export const REACTJS_SERVICE_OFFERS = [
	{
		heaing: "Years of experience with ReactJS",
		content:
			"Our ReactJS developers are well-versed with react and have years of experience in building ReactJS enterprise solutions. We help businesses to grow and thrive in the industry with our expertise and knowledge of ReactJS.",
	},
	{
		heaing: "Confidentiality",
		content:
			"We appreciate our clients necessity for confidentiality and take it seriously. Safeguarding the security and privacy of our clients is of principal importance to us, and it is our responsibility to protect our clients interests. ",
	},
	{
		heaing: "Security & Scalability",
		content:
			"Security is very crucial for defending valuable information from vandalism, fraud, and mishaps. we assure the full protection of the data of your projects. Our proficient team of ReactJS Developers creates UI and UX designs of the best quality that are aesthetic, reliable, and efficient, tailor-made for both your and your end-user requirements.",
	},
	{
		heaing: "Maintenance and Support",
		content:
			"We focus on fulfilling our customer’s needs and assisting them with their queries. The 24/7 Communication service creates a reliable and dependable relationship with our esteemed clients. Our flexible and transparent process aids our clients with sovereignty over the project.",
	},
];

export const ANGULARJS_SERVICE_OFFERS = [
	{
		heaing: "Years of experience with AngularJs",
		content:
			"Our AngularJs developers are well-versed with react and have years of experience in building AngularJs enterprise solutions. We help businesses to grow and thrive in the industry with our expertise and knowledge of AngularJs.",
	},
	{
		heaing: "Confidentiality",
		content:
			"We appreciate our clients necessity for confidentiality and take it seriously. Safeguarding the security and privacy of our clients is of principal importance to us, and it is our responsibility to protect our clients interests.",
	},
	{
		heaing: "Security & Scalability",
		content:
			"Security is very crucial for defending valuable information from vandalism, fraud, and mishaps. we assure the full protection of the data of your projects. Our proficient team of ReactJS Developers creates UI and UX designs of the best quality that are aesthetic, reliable, and efficient, tailor-made for both your and your end-user requirements.",
	},
	{
		heaing: "Maintenance and Support",
		content:
			"We focus on fulfilling our customer’s needs and assisting them with their queries. The 24/7 Communication service creates a reliable and dependable relationship with our esteemed clients. Our flexible and transparent process aids our clients with sovereignty over the project.",
	},
];

export const CODEIGNITER_SERVICE_OFFERS = [
	{
		heaing: "CodeIgniter Customization & Integration",
		content:
			"Nxinfoway provideLaravel offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. CodeIgniter gives a variety of helpful validation rules, and also accommodates your specifications too.",
	},
	{
		heaing: "E-commerce Development",
		content:
			"Nxinfoway provide best CodeIgniter is an all-in-one e-commerce platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an e-commerce platform. ",
	},
	{
		heaing: "RESTful Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers. Create a route for the resourceful controller in routes/api.",
	},
	{
		heaing: "Maintenance and support",
		content:
			"Nxinfoway maintence website Bug fixes, patching and updates for a robust, secure web and continuously high performance",
	},
];

export const WORDPRESS_SERVICE_OFFERS = [
	{
		heaing: "WordPress Website Development",
		content:
			"Nxinfoway offer excellent WordPress website development services for businesses looking forward to developing a website for their business with WordPress. Our experts create a website that performs well and turns out to be beneficial for the future of the business. ",
	},
	{
		heaing: "WordPress Theme Development",
		content:
			"Nxinfoway provide best WordPress designers design attractive and eye-catching themes that suit the website and complement its products. We create the most suitable design that attracts the users attention and gives an appealing look. We also design customized themes as per your unique requirements.",
	},
	{
		heaing: "WordPress Blog Development",
		content:
			"WordPress is a great blogging platform. Nxinfoway can help you set up a WordPress Blog in no time. We help our clients develop scalable and engaging WordPress Blog Development at the best rate.",
	},
	{
		heaing: "WordPress eCommerce development",
		content:
			"We have expertise in developing perfectly tailored eCommerce websites for our clients. We integrate all the necessary features and build a fully functional eCommerce website with great features and robust performance, providing a great user experience.",
	},
	{
		heaing: "WordPress CMS Development",
		content:
			"We specialize in making the content creation process simpler by providing top-notch WordPress CMS development. We leverage the top features of WordPress CMS and create a website that is easy to manage and has a pool of high-end features.",
	},
	{
		heaing: "WordPress Plugin Development",
		content:
			"WordPress developers are highly skilled with WordPress Plugin development, and therefore, you can hire them for the best WordPress development services.",
	},
];

export const MAGENTO_SERVICE_OFFERS = [
	{
		heaing: "Magento Ecommerce Design & Development",
		content:
			"Nxinfoway offer excellent Magento eCommerce creative and technical development. We have many years of experience in working with Magento to deliver user-friendly and highly functional websites that help you generate revenue.",
	},
	{
		heaing: "Expert Magento Developer",
		content:
			"Our experienced and technically strong certified Magento developers provide the most efficient web development services. we build Magento websites from scratch and thoroughly customise in order to cater to your business requirements.",
	},
	{
		heaing: "Magento Migration & Managed Hosting",
		content:
			"Our Magento 2 migration services lets you migrate to Magento’s latest version without losing your data and compromising with security aspect. Our experts developerr managed hosting services",
	},
	{
		heaing: "Maintenance and support",
		content:
			"Nxinfoway maintence website Bug fixes, patching and updates for a robust, secure web and continuously high performance. We complete your project at the promised time without unnecessary delays.",
	},
];

export const REACTNATIVE_SERVICE_OFFERS = [
	{
		heaing: "Customer Satisfaction",
		content:
			"Nxinfoway offer excellent work on the latest frameworks and technologies to deliver you user-friendly, scalable, secure, and new business solutions as per your need.",
	},
	{
		heaing: "Dedicated Teams",
		content:
			"We have a dedicated team of React Native developers who are well versed in delivering high-end business solutions on several frameworks and technologies.",
	},
	{
		heaing: "Integrity & Transparency",
		content:
			"We respect your ideas and vision, provide you every small project detail regularly, and consider your worthy advice when and as needed.",
	},
	{
		heaing: "Competitive Pricing & support",
		content:
			"Nxinfoway maintence apps Bug fixes, patching and updates, We offer an ideal combination of cost-effective rates with amazing quality to make sure the lowest pricing in our segment.",
	},
];

export const FLUTTER_SERVICE_OFFERS = [
	{
		heaing: "Years of experience with Flutter",
		content:
			"Our Flutter developers are well-versed with Flutter and have years of experience in building Flutter enterprise solutions. We help businesses to grow and thrive in the industry with our expertise and knowledge of Flutter.",
	},
	{
		heaing: "Confidentiality",
		content:
			"We appreciate our clients necessity for confidentiality and take it seriously. Safeguarding the security and privacy of our clients is of principal importance to us, and it is our responsibility to protect our clients interests. ",
	},
	{
		heaing: "Security & Scalability",
		content:
			"Security is very crucial for defending valuable information from vandalism, fraud, and mishaps. we assure the full protection of the data of your projects. Our proficient team of ReactJS Developers creates UI and UX designs of the best quality that are aesthetic, reliable, and efficient, tailor-made for both your and your end-user requirements.",
	},
	{
		heaing: "Maintenance and Support",
		content:
			"We focus on fulfilling our customer’s needs and assisting them with their queries. The 24/7 Communication service creates a reliable and dependable relationship with our esteemed clients. Our flexible and transparent process aids our clients with sovereignty over the project.",
	},
];

export const ldJson = {
	"@context": "http://schema.org",
	"@type": "Organization",
	name: "Nxinfoway",
	logo: `${DOMAIN}static/media/LogoNavigation.5748fe2d.svg`,
	url: { DOMAIN },
	image: `${DOMAIN}static/media/LogoNavigation.5748fe2d.svg`,
	telephone: "+91 982 591 0808",
	description:
		"Nxinfoway is a top web & mobile app development company in India that offers iPhone/iOS, Android application development services from startup to enterprise level company.",
	address: {
		"@type": "PostalAddress",
		streetAddress: "601, Pehel Lake View",
		addressLocality: "Nr. Vaishnodevi Circle,Ahmedabad",
		addressRegion: "Gujarat",
		postalCode: "382421",
		addressCountry: "India",
	},
	sameAs: [
		{ FACEBOOK_URL },
		{ TWITTER_URL },
		{ LINKEDIN_URL },
		"https://github.com/mindinventory",
		{ SKYPE_URL },
		{ INSTA_URL },
	],
	contactPoint: {
		"@type": "ContactPoint",
		telephone: "+91 982 591 0808",
		contactType: "Customer Service",
		email: "info@nxinfoway.com",
		areaServed: ["US", "CA", "UK"],
	},
	pricerange: "$$",
	openingHours: [
		"Monday 9 AM- 7 PM",
		"Tuesday 9 AM- 7 PM",
		"Wednesday 9 AM- 7 PM",
		"Thrusday 9 AM- 7 PM",
		"Friday 9 AM- 7 PM",
		"Saturday Closed",
		"Sunday Closed",
	],
	geo: {
		"@type": "GeoCoordinates",
		latitude: "23.1371428",
		longitude: "72.5527651",
	},
};

export const JAVA_SERVICE_OFFERS = [
	{
		heaing: "Custom Java Application",
		content:
			"Nxinfoway provide offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. We will provide Custom Java Application & Custom Java Product Development & Custom Java Application Development.",
	},
	{
		heaing: "ERP & CRM Development & Migrating and Porting",
		content:
			"Nxinfoway provide best ERP and CRM platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an ERP & CRM platform & Migrating and Porting.",
	},
	{
		heaing: "RESTful Development & Responsive Java Website Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers using JSP, Spring Framework. Create a route for the resourceful controller in routes/api. nxinfoway offer to Responsive Java/JSP Website Development.",
	},
	{
		heaing: "CMS & Components & Maintenance and support",
		content:
			"Nxinfoway maintence website Bug fixes, patching and updates for a robust, secure web and continuously high performance. we will offer Customization with CMS and Components & Developing Major Frameworks in JAva & Integrating with well-known Payment Gateways.",
	},
];

export const ASPNET_SERVICE_OFFERS = [
	{
		heaing: "Custom .NET Application",
		content:
			"Nxinfoway provide offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. We will provide Custom .Net Application & Custom .Net Product Development & Custom .Net Application Development.",
	},
	{
		heaing: "ERP & CRM Development & Migrating and Porting",
		content:
			"Nxinfoway provide best ERP and CRM platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an ERP & CRM platform & Migrating and Porting.",
	},
	{
		heaing: "RESTful Development & Responsive Website Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers using ASP and VB .Net. Create a route for the resourceful controller in routes/api. nxinfoway offer to Responsive Website and software Development.",
	},
	{
		heaing: "CMS & Components & Maintenance and support",
		content:
			"Nxinfoway maintence website and software Bug fixes, patching and updates for a robust, secure web and continuously high performance. we will offer Customization with CMS and Components & Developing Major Frameworks in .Net & Integrating with well-known Payment Gateways.",
	},
];
export const WEB_SERVICE_OFFERS = [
	{
		heaing: "Expert Web Designers & Developers",
		content:
			"Nxinfoway provide offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. We will provide Custom .Net Application & Custom .Net Product Development & Custom .Net Application Development.",
	},
	{
		heaing: "ERP & CRM Development & Migrating and Porting",
		content:
			"Nxinfoway provide best ERP and CRM platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an ERP & CRM platform & Migrating and Porting.",
	},
	{
		heaing: "RESTful Development & Responsive Website Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers using ASP and VB .Net. Create a route for the resourceful controller in routes/api. nxinfoway offer to Responsive Website and software Development.",
	},
	{
		heaing: "CMS & Components & Maintenance and support",
		content:
			"Nxinfoway maintence website and software Bug fixes, patching and updates for a robust, secure web and continuously high performance. we will offer Customization with CMS and Components & Developing Major Frameworks in .Net & Integrating with well-known Payment Gateways.",
	},
];

export const MOBILE_SERVICE_OFFERS = [
	{
		heaing: "Expert Web Designers & Developers",
		content:
			"Nxinfoway provide offers numerous extension points for you to modify the behaviour of the systems core components, or even replace them entirely. We will provide Custom .Net Application & Custom .Net Product Development & Custom .Net Application Development.",
	},
	{
		heaing: "ERP & CRM Development & Migrating and Porting",
		content:
			"Nxinfoway provide best ERP and CRM platform that includes tools and features that enable users to easily conceptualize, imagine, develop, design, run, operate, and manage an ERP & CRM platform & Migrating and Porting.",
	},
	{
		heaing: "RESTful Development & Responsive Website Development",
		content:
			"Wen will provides a convenient way to create Restful APIs via resourceful controllers using ASP and VB .Net. Create a route for the resourceful controller in routes/api. nxinfoway offer to Responsive Website and software Development.",
	},
	{
		heaing: "CMS & Components & Maintenance and support",
		content:
			"Nxinfoway maintence website and software Bug fixes, patching and updates for a robust, secure web and continuously high performance. we will offer Customization with CMS and Components & Developing Major Frameworks in .Net & Integrating with well-known Payment Gateways.",
	},
];
export const HIRE_REACTJS_SERVICE_OFFERS = [
	{
		heaing: "Years of experience with ReactJS",
		content:
			"Our ReactJS developers are well-versed with react and have years of experience in building ReactJS enterprise solutions. We help businesses to grow and thrive in the industry with our expertise and knowledge of ReactJS.",
	},
	{
		heaing: "Easy Migration Process",
		content:
			"Hire ReactJS programmer to ensure trouble-free ReactJS migration from any platform for accomplishing business-specific requirements.",
	},
	{
		heaing: "Custom Web Application",
		content:
			"Use the power of ReactJS to build beautiful web applications for eCommerce, social network, marketplaces and so on.",
	},
	{
		heaing: "Responsive UI",
		content:
			"Hire dedicated ReactJS developers to get fully responsive React applications that ideally fits multiple devices, operating systems, screen resolutions and browsers.",
	},
];
export const HIRE_ANGULARJS_SERVICE_OFFERS = [
	{
		heaing: "Angular Web Apps",
		content:
			"Our dedicated Angular developers create superior and robust websites with interactive UI/UX that provides a user-friendly experience.",
	},
	{
		heaing: "Angular Mobile App",
		content:
			"Develop a native or hybrid mobile application with our versatile Angular app developer. They will provide you with an interactive mobile web application.",
	},
	{
		heaing: "CMS & Web Portal",
		content:
			"We have the best market Angular developer who can create an interactive CMS or web portal for you that can provide you with the highest profit.",
	},
	{
		heaing: "Maintenance and Support",
		content:
			"We focus on fulfilling our customer’s needs and assisting them with their queries. The 24/7 Communication service creates a reliable and dependable relationship with our esteemed clients. Our flexible and transparent process aids our clients with sovereignty over the project.",
	},
];
export const DESIGN_OFFERS = [
	{
		heaing: "Quality product",
		content:
			"We continue to iterate on the product until it is exactly what you wanted.",
	},
	{
		heaing: "Quick development",
		content:
			"Our team with its rich expertise and diverse experience optimizes all processes and employs the best agile practices, leading to a reduction in custom software application development time.",
	},
	{
		heaing: "UX/UI design",
		content:
			"excellent user interfaces and beautiful designs to deliver delightful user experiences.",
	},
	{
		heaing: "Complete transparency",
		content:
			"Bug fixes, patching and updates for a robust, secure app and continuously high performance",
	},
];
export const UIUX_DESIGN_OFFERS = [
	{
		heaing: "Wireframing and Prototyping",
		content:
			"Wireframe & Prototype Design Services are created by Nxinfoway' team using technologies and methodologies that enable rapid iteration cycles. We will provide a wireframe that is low-fidelity and takes less time to create, while a prototype is high-fidelity and is more time-consuming.",
	},
	{
		heaing: "User Research",
		content:
			"Nxinfoway researchers are also experts in a variety of methods for different research goals. like research project, research method, design a study and analyze the results.",
	},
	{
		heaing: "UX strategy & Design system",
		content:
			"We can also help you build a design system that creates consistent, cohesive visual experiences across devices and platforms. A UX strategy is a plan that details how the design will work To visualise our UX strategy, we create wireframes. ",
	},
	{
		heaing: "Data Visualization",
		content:
			"Nxinfoway consultants enable data visualization for customers to spot trends, track business goal achievements, compare the performance of various categories, products, brands, etc.",
	},
];
export const FINTECH_OFFERS = [
	{
		heaing: "Crowdfunding Portal Development",
		content:
			"We build innovative, comprehensive and brand-recognition and trust crowdfunding platforms to bring businesses closer to investors and help clients build an active business community.",
	},
	{
		heaing: "Insurance Apps Solutions",
		content:
			"We build programmes for insurance agents and customers with policy information and claims filing for the insurance sector. we will provide Health insurance apps, Car insurance apps, Life insurance apps, Travel insurance apps.",
	},
	{
		heaing: "Scalability",
		content:
			"Our FinTech app design team builds robust and scalable apps that grow along with the expansion of your enterprise. Nxinfoway is the most preferred FinTech application development agency across the verticals to work with.",
	},
	{
		heaing: "Maintenance and Support",
		content:
			"We provide 24x7 FinTech app development support to ensure that your application is always ready and bug-free to serve your customers.",
	},
];
export const REAL_ESTATE_OFFERS = [
	{
		heaing: "Customer Satisfaction",
		content:
			"We build innovative, comprehensive and brand-recognition and trust Online Property Booking Solutions to bring businesses closer to investors and help clients build an active business community.",
	},
	{
		heaing: "Flexible Engagement Models",
		content:
			"We build engagement models are designed to match the size and scale of your operations. Our best breed of solutions incorporates industry best practices and latest technology to support your business goals.",
	},
	{
		heaing: "Dedicated Teams",
		content:
			"We have a dedicated team of developers who are well versed in delivering high-end business solutions across several frameworks and technologies.",
	},
	{
		heaing: "Flexible Client Support",
		content:
			"We offer extensive support 24*7 to our clients according to their time zone and requirements at the time of development and post-development.",
	},
];
export const HEALTHCARE_OFFERS = [
	{
		heaing: "Fully-branded healthcare mobile app and web development",
		content:
			"We build innovative, comprehensive and brand-recognition and trust Online Healthcare Solutions to dynamic healthcare apps for hospitals, healthcare providers, and patients and doctors.",
	},
	{
		heaing: "Flexible Engagement Models & On-Time Delivery",
		content:
			"We build engagement models are designed to match the size and scale of your operations. Our best breed of solutions incorporates industry best practices and latest technology to support your business goals.",
	},
	{
		heaing: "Dedicated Teams",
		content:
			"We have a dedicated team of developers who are well versed in delivering high-end business solutions across several frameworks and technologies.",
	},
	{
		heaing: "Flexible Client Support",
		content:
			"We offer extensive support 24*7 to our clients according to their time zone and requirements at the time of development and post-development.",
	},
];
export const GANRAL_OFFERS = [
	{
		heaing: "Customer Satisfaction",
		content:
			"We build innovative, comprehensive and brand-recognition and trust Online software solutions our global client.",
	},
	{
		heaing: "Flexible Engagement Models & On-Time Delivery",
		content:
			"We build engagement models are designed to match the size and scale of your operations. Our best breed of solutions incorporates industry best practices and latest technology to support your business goals.",
	},
	{
		heaing: "Dedicated Teams",
		content:
			"We have a dedicated team of developers who are well versed in delivering high-end business solutions across several frameworks and technologies.",
	},
	{
		heaing: "Flexible Client Support",
		content:
			"We offer extensive support 24*7 to our clients according to their time zone and requirements at the time of development and post-development.",
	},
];

export const INDUSTRY_OFFERS = [
	{
		heaing: "Customer Satisfaction",
		content:
			"We build innovative, comprehensive and brand-recognition and trust Online software solutions our global client.",
	},
	{
		heaing: "Flexible Engagement Models & On-Time Delivery",
		content:
			"We build engagement models are designed to match the size and scale of your operations. Our best breed of solutions incorporates industry best practices and latest technology to support your business goals.",
	},
	{
		heaing: "Dedicated Teams",
		content:
			"We have a dedicated team of developers who are well versed in delivering high-end business solutions across several frameworks and technologies.",
	},
	{
		heaing: "Flexible Client Support",
		content:
			"We offer extensive support 24*7 to our clients according to their time zone and requirements at the time of development and post-development.",
	},
];
