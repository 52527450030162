import AMGroupLogo from "../assets/images/products/am-group/amgroup-logo.png";
import AMGroupBanner from "../assets/images/products/am-group/am_group_a.jpg";
import AMGroupImage1 from "../assets/images/products/am-group/am_group_b.jpg";
import AMGroupImage2 from "../assets/images/products/am-group/am_group_c.jpg";
import AMGroupImage3 from "../assets/images/products/am-group/am_group_d.jpg";
import AMGroupImage4 from "../assets/images/products/am-group/am_group_e.jpg";

import HappyGroceryLogo from "../assets/images/products/happy-grocery/logo.png";
import HappyGroceryImage1 from "../assets/images/products/happy-grocery/happygocery_a.jpg";
import HappyGroceryImage2 from "../assets/images/products/happy-grocery/happygocery_b.jpg";
import HappyGroceryImage3 from "../assets/images/products/happy-grocery/happygocery_c.jpg";
import HappyGroceryImage4 from "../assets/images/products/happy-grocery/happygocery_d.jpg";
import HappyGroceryImage5 from "../assets/images/products/happy-grocery/happygocery_e.jpg";

import BuySellLogo from "../assets/images/products/buy-sell/buysell_logo.png";
import BuySellImage1 from "../assets/images/products/buy-sell/buysell_a.jpg";
import BuySellImage2 from "../assets/images/products/buy-sell/buysell_b.jpg";
import BuySellImage3 from "../assets/images/products/buy-sell/buysell_c.jpg";
import BuySellImage4 from "../assets/images/products/buy-sell/buysell_d.jpg";
import BuySellImage5 from "../assets/images/products/buy-sell/buysell_e.jpg";

import HappyHomeLogo from "../assets/images/products/happy-home/happy_home_logo.png";
import HappyHomeImage1 from "../assets/images/products/happy-home/happy_home_a.jpg";
import HappyHomeImage2 from "../assets/images/products/happy-home/happy_home_b.jpg";
import HappyHomeImage3 from "../assets/images/products/happy-home/happy_home_c.jpg";
import HappyHomeImage4 from "../assets/images/products/happy-home/happy_home_d.jpg";
import HappyHomeImage5 from "../assets/images/products/happy-home/happy_home_e.jpg";

import ChamberLinkLogo from "../assets/images/products/chember-link/chember_link_logo.png";
import ChamberLinkImage1 from "../assets/images/products/chember-link/chember_link_a.jpg";
import ChamberLinkImage2 from "../assets/images/products/chember-link/chember_link_b.jpg";
import ChamberLinkImage3 from "../assets/images/products/chember-link/chember_link_c.jpg";
import ChamberLinkImage4 from "../assets/images/products/chember-link/chember_link_d.jpg";
import ChamberLinkImage5 from "../assets/images/products/chember-link/chember_link_e.jpg";

import ZooglaLogo from "../assets/images/products/zoogla/logo.png";
import ZooglaImage1 from "../assets/images/products/zoogla/zoogla_a.jpg";
import ZooglaImage2 from "../assets/images/products/zoogla/zoogla_b.jpg";
import ZooglaImage3 from "../assets/images/products/zoogla/zoogla_c.jpg";
import ZooglaImage4 from "../assets/images/products/zoogla/zoogla_d.jpg";
import ZooglaImage5 from "../assets/images/products/zoogla/zoogla_e.jpg";

import EfremoveLogo from "../assets/images/products/efremove/efremove_logo.png";
import EfremoveImage1 from "../assets/images/products/efremove/efremove_a.jpg";
import EfremoveImage2 from "../assets/images/products/efremove/efremove_b.jpg";
import EfremoveImage3 from "../assets/images/products/efremove/efremove_c.jpg";
import EfremoveImage4 from "../assets/images/products/efremove/efremove_d.jpg";
import EfremoveImage5 from "../assets/images/products/efremove/efremove_e.jpg";

export const allProject = [
	{
		id: 1,
		logo: AMGroupLogo,
		slug: "life-is-wine",
		name: "Life is Wine",
		banner: AMGroupBanner,
		mainParagraph:
			"A complete eCommerce multi vendor solution with beautiful and modern design, Being native to both mobile platforms it builds to delivers fast and smooth performance.",
		technologies: ["iOS App", "Android App", "Admin & Super Admin panel"],
		heading1:
			"AM Group is chain of multiple restaurants, Bars located in Russia, This app connects all of them.",
		content1: [
			"Created to provide an awesome and wonderful e-commerce experience to your delightful customers in everyday use.",
			"We aimed to make this product to provide faster and smoother performance as possible in the an ecommerce app segment , So we simply followed all the needful standards while building this product.",
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"iOS App Development",
					"Android App Development",
					"Back-end Support",
				],
			},
			{
				header: "Technology used",
				items: ["iOS", "Android", "Laravel"],
			},
		],
		heading2: "The client",
		content2: [
			"AM Group is a corporate innovation and venture development firm from Mosco, Russia. Their purpose is to unleash the power of entrepreneurship to solve big problems for the world’s leading companies. They do this by accelerating, scaling and investing in new business ideas, in partnership with brands and startups.",
		],
		heading3: "The challenge",
		content3: [
			"Rainmaking approached us at a point in their growth when they were going from strength to strength. They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at AM Group. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: AMGroupImage1,
			},
			{
				header: "#image2",
				imgSrc: AMGroupImage2,
			},
			{
				header: "#image3",
				imgSrc: AMGroupImage3,
			},
			{
				header: "#image4",
				imgSrc: AMGroupImage4,
			},
		],
		links: ["#", "#", "#"],
	},
	{
		id: 2,
		logo: HappyGroceryLogo,
		slug: "happy-grocery",
		name: "Happy Grocery",
		banner: HappyGroceryImage1,
		mainParagraph:
			"A complete eCommerce multi vendor solution with beautiful and modern design, Being native to both mobile platforms it builds to delivers fast and smooth performance.",
		technologies: ["iOS App", "Android App", "Admin & Super Admin panel"],
		heading1:
			"Happy grocery provide multi vendor solutions for grocery application located in India and Nepal.",
		content1: [
			"Created to provide an awesome and wonderful e-commerce experience to your delightful customers in everyday use.",
			"We aimed to make this product to provide faster and smoother performance as possible in the an ecommerce app segment , So we simply followed all the needful standards while building this product.",
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"iOS App Development",
					"Android App Development",
					"Back-end Support",
					"Rest API Developmenr",
				],
			},
			{
				header: "Technology used",
				items: [
					"iOS",
					"Android",
					"Laravel",
					"Php",
					"Html5",
					"CSS3",
					"Javascript",
					"Bootstrap",
					"My-SQL",
				],
			},
		],
		heading2: "The client",
		content2: [
			"Happy Grocery is a corporate innovation and venture development firm from Nepal and India. Their purpose is to unleash the power of entrepreneurship to solve big problems for the world’s leading companies. They do this by accelerating, scaling and investing in new business ideas, in partnership with brands and startups.",
		],
		heading3: "The challenge",
		content3: [
			"Rainmaking approached us at a point in their growth when they were going from strength to strength. They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at Happy-Grocery Food Market. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: HappyGroceryImage2,
			},
			{
				header: "#image2",
				imgSrc: HappyGroceryImage3,
			},
			{
				header: "#image3",
				imgSrc: HappyGroceryImage4,
			},
			{
				header: "#image4",
				imgSrc: HappyGroceryImage5,
			},
		],
		links: ["#", "#", "#"],
	},
	{
		id: 3,
		logo: BuySellLogo,
		slug: "buy-sell",
		name: "Xpres - Service Provider Solution",
		banner: BuySellImage1,
		mainParagraph:
			"This market place is an all in one platform that provides services from professionals and also help users buying, selling items easily.",
		technologies: ["iOS App", "Android App", "Admin panel"],
		heading1:
			"Xpres provide buy and sell items and service provider application located in India and US.",
		content1: [
			"Created to provide an awesome and wonderful MarketPlus experience to your delightful customers in everyday use.",
			"We aimed to make this product to provide faster and smoother performance as possible in the an marketplus app segment , So we simply followed all the needful standards while building this product.",
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"iOS App Development",
					"Android App Development",
					"Back-end Support",
					"Rest API Developmenr",
				],
			},
			{
				header: "Technology used",
				items: [
					"iOS",
					"Android",
					"Laravel",
					"Php",
					"Html5",
					"CSS3",
					"Javascript",
					"Bootstrap",
					"My-SQL",
				],
			},
		],
		heading2: "The client",
		content2: [
			"Xpres is a corporate innovation and venture development firm from India and US. Their purpose is to unleash the power of entrepreneurship to solve big problems for the world’s leading companies. They do this by accelerating, scaling and investing in new business ideas, in partnership with brands and startups.",
		],
		heading3: "The challenge",
		content3: [
			"Rainmaking approached us at a point in their growth when they were going from strength to strength. They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at Marketplus and Service provider Market. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: BuySellImage2,
			},
			{
				header: "#image2",
				imgSrc: BuySellImage3,
			},
			{
				header: "#image3",
				imgSrc: BuySellImage4,
			},
			{
				header: "#image4",
				imgSrc: BuySellImage5,
			},
		],
		links: ["/service/ondemand-app-development", "/service/ondemand-app-development", "/service/ondemand-app-development"],
	},
	{
		id: 3,
		logo: HappyHomeLogo,
		slug: "happy-home",
		name: "Happy Home",
		banner: HappyHomeImage1,
		mainParagraph:
			"This is home services provider website to book register and book home service online.",
		technologies: ["Website", "Admin panel"],
		heading1: "Happy home is home service provider application located in UK.",
		content1: [
			"Created to provide an awesome and wonderful MarketPlus experience to your delightful customers in everyday use.",
			"We aimed to make this product to provide faster and smoother performance as possible in the an marketplus app segment , So we simply followed all the needful standards while building this product.",
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"Website Development",
					"Back-end Support",
					"Rest API Developmenr",
				],
			},
			{
				header: "Technology used",
				items: [
					"Laravel",
					"Php",
					"Html5",
					"CSS3",
					"Javascript",
					"Bootstrap",
					"My-SQL",
				],
			},
		],
		heading2: "The client",
		content2: [
			"Happy to help is a corporate innovation and venture development firm from India and US. Their purpose is to unleash the power of entrepreneurship to solve big problems for the world’s leading companies. They do this by accelerating, scaling and investing in new business ideas, in partnership with brands and startups.",
		],
		heading3: "The challenge",
		content3: [
			"Rainmaking approached us at a point in their growth when they were going from strength to strength. They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at Marketplus and Service provider Market. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: HappyHomeImage2,
			},
			{
				header: "#image2",
				imgSrc: HappyHomeImage3,
			},
			{
				header: "#image3",
				imgSrc: HappyHomeImage4,
			},
			{
				header: "#image4",
				imgSrc: HappyHomeImage5,
			},
		],
		links: ["#", "#", "#", "#"],
	},
	{
		id: 3,
		logo: ChamberLinkLogo,
		slug: "chamber-link",
		name: "ChamberLink of South US",
		banner: ChamberLinkImage1,
		mainParagraph:
			"Chamber-Link of South US Application use to display news, Members, Chamber Of directory List, Offres.",
		technologies: ["iOS App", "Android App", "Admin panel", "Website"],
		heading1: "Chamber Link website and  application located in South US.",
		content1: [
			"ChmaberLink is use to memebres, company, news, chamberof directory and offers for south US",
			"We aimed to make this product to provide faster and smoother performance as possible in the an marketplus app segment , So we simply followed all the needful standards while building this product.",
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"iOS App Development",
					"Android App Development",
					"Back-end Support",
					"Rest API Developmenr",
				],
			},
			{
				header: "Technology used",
				items: [
					"iOS",
					"Android",
					"Laravel",
					"Php",
					"Html5",
					"CSS3",
					"Javascript",
					"Bootstrap",
					"My-SQL",
				],
			},
		],
		heading2: "The client",
		content2: [
			"Happy to help is a corporate innovation and venture development firm from India and US. Their purpose is to unleash the power of entrepreneurship to solve big problems for the world’s leading companies. They do this by accelerating, scaling and investing in new business ideas, in partnership with brands and startups.",
		],
		heading3: "The challenge",
		content3: [
			"Rainmaking approached us at a point in their growth when they were going from strength to strength. They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at Marketplus and Service provider Market. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: ChamberLinkImage2,
			},
			{
				header: "#image2",
				imgSrc: ChamberLinkImage3,
			},
			{
				header: "#image3",
				imgSrc: ChamberLinkImage4,
			},
			{
				header: "#image4",
				imgSrc: ChamberLinkImage5,
			},
		],
		links: ["#", "#", "#", "#", "#"],
	},
	{
		id: 4,
		logo: ZooglaLogo,
		slug: "zoogla",
		name: "Zoogla - Fintech Solutions",
		banner: ZooglaImage2,
		mainParagraph:
			"Zoogla is a digital platform that provides investment solutions to promote financial growth for customers.",
		technologies: ["iOS App", "Android App", "Admin panel", "Website"],
		heading1: "Financial Services - Helping you achieve your financial goals",
		content1: [
			"Open banking solution. It offers a platform that enables users to integrate multiple bank accounts to manage income and expenses.",
			"We aimed to make this product to provide faster and smoother performance as possible in the an marketplus app segment , So we simply followed all the needful standards while building this product.",
			"Budgeting tools, Financial tracking, Financial planning, FinTech, Open Banking, Personal finance, Financial wellbeing, Digital money, Finance, Payments, and Money management"
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"iOS App Development",
					"Android App Development",
					"Back-end Support",
					"Rest API Developmenr",
				],
			},
			{
				header: "Technology used",
				items: [
					"iOS",
					"Android",
					"Laravel",
					"NodeJS",
					"Html5",
					"CSS3",
					"Angular",
					"Bootstrap",
					"MongoDB",
				],
			},
		],
		heading2: "The client",
		content2: [
			"Zoogla is a corporate innovation and venture development firm from UK. We’re a fintech startup that was founded in the UK at the outset of the COVID-19 pandemic and that has created a wall-to-wall digital banking app that allows you to connect and manage your entire financial life in one place. With transparent, fair and simple tools, the app is designed to help you spend wisely, avoid unfair fees, start saving more and build a better credit score.",
		],
		heading3: "The challenge",
		content3: [
			"There are several challenges that come with developing software for the fintech industry. From regulatory compliance to security, these hurdles can make or break your product and lead you to success (or failure). They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at Well-documented software modifications are essential for being able to quickly detect a breach in case it occurs. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: ZooglaImage1,
			},
			{
				header: "#image2",
				imgSrc: ZooglaImage3,
			},
			{
				header: "#image3",
				imgSrc: ZooglaImage4,
			},
			{
				header: "#image4",
				imgSrc: ZooglaImage5,
			},
		],
		links: ["/service/fintech-app-development-company", "/service/fintech-app-development-company", "/service/fintech-app-development-company", "/service/fintech-app-development-company", "/service/fintech-app-development-company"],
	},
	{
		id: 4,
		logo: EfremoveLogo,
		slug: "efremov-gold",
		name: "EFREMOV gold",
		banner: EfremoveImage1,
		mainParagraph:
			"EFREMOV is jewelry Online Store that provides buy Gold, Silver, Lemon Gold, White Gold, Red Gold Jewelry.",
		technologies: ["iOS App", "Android App", "Website", "Admin Panel"],
		heading1: "Buy gold jewelry in the Efremov.gold online store",
		content1: [
			"The EFREMOV brand was founded in 2008. Today the company is in the TOP-5 of the best jewelry manufacturers in Russia. Our exclusive products are represented in more than 1000 branches in Moscow and throughout Russia.",
			"We aimed to make this product to provide faster and smoother performance as possible in the an marketplus app segment , So we simply followed all the needful standards while building this product.",
			"Type of features like catalog, Cart, Order, Wishlist, Order and Payment Integration, Near by store, Social Integration etc."
		],
		badges: [
			{
				header: "Service Provided",
				items: [
					"Wireframing",
					"UX UI Design",
					"iOS App Development",
					"Android App Development",
					"Website Development",
					"Back-end Support",
					"Rest API Developmenr",
				],
			},
			{
				header: "Technology used",
				items: [
					"iOS",
					"Android",
					"Laravel",
					"NodeJS",
					"Html5",
					"ReactJs",
					"Bootstrap",
					"MongoDB",
				],
			},
		],
		heading2: "The client",
		content2: [
			"EFREMOV is Online jewelry Store that provides buy Gold in Rasia. On its pages you will find the most interesting things about the world of jewelry, fashion, style and the people who develop our brand.",
		],
		heading3: "The challenge",
		content3: [
			"There are several challenges that come with developing software for the online jewelry store. Understanding the Scope of the Project,Determining What is Important to the User, Coding for Multiple Platforms, Designing a Functional Interface , security, these hurdles can make or break your product and lead you to success (or failure). They had just completed a strategic repositioning of the brand, and ready to launch their new purpose (unleash the entrepreneur), they needed a new visual identity and website that could reflect this exciting new space they intend to carve in the market.",
		],
		heading4: "The solution",
		content4: [
			"For this project we collaborated closely with our clients at Well-documented software modifications are essential for being able to quickly detect a breach in case it occurs. They had already carried out extensive user research, but were relying on us to bring their vision to life and introduce a fresh perspective from outside their organization.",
			"Paying close attention to their new brand platform, we created a new visual identity, complete with bespoke illustration and iconography, then rolled this out across a sparkling new modular mobile apps.",
		],
		visualInsights: [
			{
				header: "#image1",
				imgSrc: EfremoveImage2,
			},
			{
				header: "#image2",
				imgSrc: EfremoveImage3,
			},
			{
				header: "#image3",
				imgSrc: EfremoveImage4,
			},
			{
				header: "#image4",
				imgSrc: EfremoveImage5,
			},
		],
		links: ["", "", "", "", ""],
	},
];
