import React from 'react';

const SmLink = (props) => {

    const { url, textAlign, label, onClick } = props;

    return (
        <a href={url} className={`text-white ${textAlign} smlink`} onClick={onClick}>{label}</a>
    )
}

export default SmLink
